import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { ClaimType } from '../../../../../claim/claim-types';
import { BenefitCategory, BenefitCategoryDread } from '../../../../../codes/benefit-category';
import { ClaimConditions, ClaimConditionsDread } from '../../../../../codes/claim-condition';
import { CurrentTreatment } from '../../../../../codes/claim-event';
import { Conditions } from '../../../../../codes/conditions';
import {
    DetailedClaimConditions,
    DetailedClaimConditionsDread,
} from '../../../../../codes/detailed-claim-condition';
import { MajorClaimCause } from '../../../../../codes/major-claim-cause';
import {
    Condition,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    validations,
    WhenFieldChanges,
} from '../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    TelephoneNumberMask,
} from '../../../../../forms/input-masks';
import { requiredValidator } from '../../../../../forms/validations';
import {
    maxDateValidator,
    minDateValidator,
} from '../../../../../forms/validations/date-validator';
import { State } from '../../../../../redux/root-reducer';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';

type Props = StyledComponent & {
    claimType: Nullable<ClaimType>;
    heading: React.ReactNode;
    showTreatingSpecialist?: boolean;
};
const ClaimDetailsForm: React.FunctionComponent<Props> = ({
    claimType,
    classes,
    heading,
    showTreatingSpecialist,
}: Props) => {
    const { categoryOptions, claimConditionsOptions, detailedClaimConditions } = getClaimOptions(
        claimType
    );

    const [selectedMajorClaimCause, setSelectedMajorClaimCause] = React.useState<string>('');
    const [selectedCategory, setSelectedCategory] = React.useState<string>('');
    const [selectedClaimCondition, setSelectedClaimCondition] = React.useState<string>('');

    const getMajorClaimCauseOptions = (currentValue: string) => {
        if (!MajorClaimCause || !Array.isArray(MajorClaimCause)) {
            return [];
        }

        const currentOptionExists = MajorClaimCause.some(option => option.value === currentValue);
        const filteredMajorClaimCause = MajorClaimCause.filter(cause =>
            categoryOptions?.some(benefit => benefit.Category === cause.category)
        );

        const currentMajorClaim =
            MajorClaimCause &&
            filteredMajorClaimCause.find(option => option.category === currentValue);

        const setValue = currentOptionExists
            ? filteredMajorClaimCause
            : [
                  ...filteredMajorClaimCause,
                  {
                      value: currentValue,
                      label: currentValue,
                      category: 'Unknown',
                  },
              ];

        if (currentMajorClaim) {
            setSelectedMajorClaimCause(currentMajorClaim?.category || '');
        }

        const visibleOptions = setValue.filter(option => option.value && option.label);

        const dynamicOption = currentOptionExists
            ? []
            : [{ value: currentValue, label: currentValue }];

        if (visibleOptions.length === 0) {
            return [{ value: 'Not applicable', label: 'Not applicable' }];
        }

        return [...dynamicOption, ...visibleOptions];
    };

    const getCategoryOptions = (currentValue: string) => {
        if (currentValue === '') return [];
        let filteredCategoryOptions: typeof categoryOptions = [];

        if (!categoryOptions || !Array.isArray(categoryOptions)) {
            return filteredCategoryOptions;
        }

        if (!selectedMajorClaimCause) {
            filteredCategoryOptions = categoryOptions;
        } else {
            filteredCategoryOptions = categoryOptions.filter(
                option => option?.Category === currentValue
            );
        }

        const currentOptionExists = categoryOptions.some(
            option => option.Category === currentValue
        );

        const visibleOptions = filteredCategoryOptions.filter(
            option => option.value && option.label
        );

        const dynamicOption = currentOptionExists
            ? []
            : currentValue
            ? [{ value: currentValue, label: currentValue }]
            : [];

        if (visibleOptions.length === 0) {
            return [...dynamicOption, { value: 'Not applicable', label: 'Not applicable' }];
        }

        return [...dynamicOption, ...visibleOptions];
    };

    const filteredCategoryOptions = getCategoryOptions(selectedMajorClaimCause);

    const setCategorySelection = (currentValue: string) => {
        setSelectedCategory(currentValue || '');
    };

    const getClaimConditionOptions = (currentValue: string) => {
        if (currentValue === '') return [];
        let claimConditionOptions: typeof claimConditionsOptions = [];

        if (!claimConditionsOptions || !Array.isArray(claimConditionsOptions)) {
            return claimConditionOptions;
        }

        if (!claimConditionOptions) {
            claimConditionOptions = claimConditionsOptions;
        } else {
            claimConditionOptions = claimConditionsOptions.filter(
                option => option.Category === currentValue
            );
        }

        const currentOptionExists = claimConditionsOptions.some(
            option => option.Category === currentValue
        );

        const visibleOptions = claimConditionOptions.filter(option => option.value && option.label);

        const dynamicOption = currentOptionExists
            ? []
            : currentValue
            ? [{ value: '', label: '', category: '' }]
            : [];

        if (visibleOptions.length === 0) {
            return [...dynamicOption, { value: 'Not applicable', label: 'Not applicable' }];
        }

        return [...dynamicOption, ...visibleOptions];
    };

    const claimConditionOptions = getClaimConditionOptions(selectedCategory);

    const setclaimConditionSelection = (currentValue: string) => {
        setSelectedClaimCondition(currentValue || '');
    };

    const getClaimConditionDetailOptions = (currentValue: string) => {
        if (currentValue === '') return [];
        let filteredClaimConditionDetailsOptions: typeof detailedClaimConditions = [];

        if (!detailedClaimConditions || !Array.isArray(detailedClaimConditions)) {
            return filteredClaimConditionDetailsOptions;
        }

        if (!selectedMajorClaimCause) {
            filteredClaimConditionDetailsOptions = detailedClaimConditions;
        } else {
            filteredClaimConditionDetailsOptions = detailedClaimConditions.filter(
                option => option.Category === currentValue
            );
        }

        const currentOptionExists = detailedClaimConditions.some(
            option => option.Category === currentValue
        );

        const visibleOptions = filteredClaimConditionDetailsOptions.filter(
            option => option.value && option.label
        );

        const dynamicOption = currentOptionExists
            ? []
            : currentValue
            ? [{ value: '', label: '', category: '' }]
            : [];

        if (visibleOptions.length === 0) {
            return [...dynamicOption, { value: 'Not applicable', label: 'Not applicable' }];
        }

        return [...dynamicOption, ...visibleOptions];
    };

    const filteredClaimConditionDetailsOptions = getClaimConditionDetailOptions(
        selectedClaimCondition
    );

    return (
        <React.Fragment>
            <Typography variant="h5">{heading} </Typography>
            <Divider></Divider>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="dateOfEvent"
                        validate={validations.composeValidators(
                            validations.requiredValidator,
                            maxDateValidator('dateClaimReported')
                        )}
                        maxDateField="dateClaimReported"
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="dateClaimReported"
                        validate={validations.composeValidators(
                            validations.requiredValidator,
                            minDateValidator('dateOfEvent')
                        )}
                        minDateField="dateOfEvent"
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="medicalConditionType" validate={requiredValidator}>
                        <RadioOption value="Disease" />
                        <RadioOption value="Accident" />
                    </FullWidthRadioGroup>
                    <WhenFieldChanges
                        field="medicalConditionType"
                        set="condition"
                        to={(type: string) => (type === 'Disease' ? 'Natural' : null)}
                    />
                </Grid>
                <Condition when="medicalConditionType" is="Accident">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthRadioGroup
                                label="Accident reported to the police"
                                name="reportedToPolice"
                                validate={requiredValidator}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                    </True>
                </Condition>
                <WhenFieldChanges field="medicalConditionType" set="reportedToPolice" to={null} />
                <WhenFieldChanges field="reportedToPolice" set="caseNumber" to={null} />
                <WhenFieldChanges field="reportedToPolice" set="accidentDetails" to={null} />
                <WhenFieldChanges field="reportedToPolice" set="policeStation" to={null} />
                <Condition when="reportedToPolice" is="Yes">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField name="policeStation" />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField name="caseNumber" />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="accidentDetails"
                                label="Accident details"
                                multiline
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Cause of Claim
                    </Typography>
                </Grid>
                <Field
                    name="medicalConditionType"
                    subscription={{ value: true }}
                    component={({ input: { value: medicalConditionType } }) => {
                        let options = Conditions.filter(x => x.value !== 'Natural');
                        let disabled = false;
                        if (medicalConditionType === 'Disease') {
                            disabled = true;
                            options = Conditions;
                        }
                        return (
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    label="Natural/Unnatural"
                                    name="condition"
                                    options={options}
                                    validate={requiredValidator}
                                    disabled={disabled}
                                />
                            </Grid>
                        );
                    }}
                />
                <Grid item xs={6}>
                    <Field
                        name="majorClaimCause"
                        subscription={{ value: true }}
                        component={({
                            input: { value: currentValue, onChange, ...restInput },
                            form,
                        }) => {
                            const options = getMajorClaimCauseOptions(currentValue);

                            return (
                                <FullWidthSearchSelectField
                                    label="Major claim cause"
                                    name="majorClaimCause"
                                    helperText={
                                        currentValue &&
                                        !MajorClaimCause.some(
                                            option => option.value === currentValue
                                        ) &&
                                        currentValue !== 'Not applicable' &&
                                        currentValue !== '' ? (
                                            <span>
                                                Previously selected value: `
                                                <strong>{currentValue}</strong>` (no longer
                                                available in options)
                                            </span>
                                        ) : (
                                            ''
                                        )
                                    }
                                    options={options}
                                    value={currentValue}
                                    onChange={(e: any) => {
                                        currentValue = e;
                                    }}
                                    validate={requiredValidator}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="claimCategory"
                        subscription={{ value: true }}
                        component={({ input: { value: currentValue, onChange, ...restInput } }) => {
                            setCategorySelection(currentValue);

                            return (
                                <FullWidthSearchSelectField
                                    label="Claim Category"
                                    name="claimCategory"
                                    helperText={
                                        currentValue &&
                                        !filteredCategoryOptions.some(
                                            option => option.value === currentValue
                                        ) &&
                                        currentValue !== 'Not applicable' &&
                                        currentValue !== '' ? (
                                            <span>
                                                Previously selected value: `
                                                <strong>{currentValue}</strong>` (no longer
                                                available in options)
                                            </span>
                                        ) : (
                                            ''
                                        )
                                    }
                                    options={filteredCategoryOptions}
                                    value={currentValue}
                                    onChange={onchange}
                                    validate={requiredValidator}
                                />
                            );
                        }}
                    />
                </Grid>
                <Condition when="claimCategory" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="claimCategoryDesc"
                                label="Claim category (free text)"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField label="Comments on cause" name="comments" multiline />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="claimCondition"
                        subscription={{ value: true }}
                        component={({ input: { value: currentValue, onChange, ...restInput } }) => {
                            setclaimConditionSelection(
                                currentValue && currentValue.split(' - ').length > 2
                                    ? currentValue.split(' - ')[0]
                                    : currentValue
                            );
                            return (
                                <FullWidthSearchSelectField
                                    label="Claim Condition"
                                    name="claimCondition"
                                    helperText={
                                        currentValue &&
                                        !claimConditionOptions.some(
                                            option => option.value === currentValue
                                        ) &&
                                        currentValue !== 'Not applicable' &&
                                        currentValue !== '' ? (
                                            <span>
                                                Previously selected value: `
                                                <strong>{currentValue}</strong>` (no longer
                                                available in options)
                                            </span>
                                        ) : (
                                            ''
                                        )
                                    }
                                    fullWidth
                                    options={claimConditionOptions}
                                    onChange={onChange}
                                    value={currentValue}
                                    validate={requiredValidator}
                                />
                            );
                        }}
                    />
                </Grid>
                <Condition when="claimCondition" is="Other">
                    <True>
                        <Grid item xs={12}>
                            <FullWidthTextField
                                name="claimConditionDesc"
                                label="Claim condition (free text)"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>

                <Grid item xs={12}>
                    <Field
                        name="detailedClaimCondition"
                        subscription={{ value: true }}
                        component={({ input: { value: currentValue, onChange, ...restInput } }) => {
                            return (
                                <FullWidthSearchSelectField
                                    label="Detailed Claim Condition"
                                    name="detailedClaimCondition"
                                    helperText={
                                        currentValue &&
                                        !filteredClaimConditionDetailsOptions.some(
                                            option => option.value === currentValue
                                        ) &&
                                        currentValue !== 'Not applicable' &&
                                        currentValue !== '' ? (
                                            <span>
                                                Previously selected value: `
                                                <strong>{currentValue}</strong>` (no longer
                                                available in options)
                                            </span>
                                        ) : (
                                            ''
                                        )
                                    }
                                    options={filteredClaimConditionDetailsOptions}
                                    value={currentValue}
                                    onChange={onChange}
                                    validate={requiredValidator}
                                />
                            );
                        }}
                    />
                </Grid>
                <Condition when="detailedClaimCondition" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="detailedClaimConditionDesc"
                                label="Detailed claim condition (free text)"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Treatment
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="currentTreatmentPlan"
                        label="Current treatment plan"
                        options={CurrentTreatment}
                    />
                </Grid>
                <WhenFieldChanges
                    field="currentTreatmentPlan"
                    becomes="Other"
                    set="highLevelDescriptionOfTreatmentOther"
                    to={null}
                />
                <Condition when="currentTreatmentPlan" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                label="High-level description of the current treatment plan"
                                name="highLevelDescriptionOfTreatmentOther"
                                validate={requiredValidator}
                                multiline
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Detailed description of current treatment Plan"
                        name="descriptionOfCurrentTreatmentPlan"
                        multiline
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Detailed description of Current Medication"
                        name="descriptionOfCurrentMedication"
                        multiline
                    />
                </Grid>
                {showTreatingSpecialist && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.marginTop}>
                                Treating Specialist
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistInitials"
                                mask={InitialsMask}
                                inputProps={{
                                    style: { textTransform: 'uppercase' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistName"
                                validate={validations.requiredValidator}
                                mask={FirstNameMask}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistSurname"
                                validate={validations.requiredValidator}
                                mask={LastNameMask}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistTelephoneNumber"
                                mask={TelephoneNumberMask}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistRegistrationNumber"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
};

function getClaimOptions(claimType: Nullable<ClaimType>) {
    if (claimType === ClaimType.Dread) {
        return {
            categoryOptions: BenefitCategoryDread,
            claimConditionsOptions: ClaimConditionsDread,
            detailedClaimConditions: DetailedClaimConditionsDread,
        };
    }

    return {
        categoryOptions: BenefitCategory,
        claimConditionsOptions: ClaimConditions,
        detailedClaimConditions: DetailedClaimConditions,
    };
}

const mapStateToProps = (state: State) => ({
    claimType: getClaim(state).claimType,
});

export default connect(mapStateToProps, {})(withStyles(styles)(ClaimDetailsForm));
