import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { AnyAction } from 'redux';
import { connect, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { SubmitClaimDetails, submitClaimDetailsAction } from '../actions/submit-claim-details';
import { ClaimDetails, getClaimDetails } from '../claim-details-reducer';
import ClaimDetailsForm from './claim-details-form';
import { ButtonWrapper } from '../../../../../forms';

interface DispatchProps {
    openEditModal: OpenEditModal;
    submitClaimDetails: SubmitClaimDetails;
}
interface OwnProps {}
type InnerProps = StyledComponent &
    OwnProps &
    DispatchProps & {
        readonly causeOfClaimLabel: React.ReactNode;
        readonly claimDetails: ClaimDetails;
        readonly showTreatingSpecialist?: boolean;
        readonly isReadOnly: boolean;
    };
const ClaimDetailsFormModal = EditFormModal<ClaimDetails>();
const claimDetailsView: React.FunctionComponent<InnerProps> = ({
    classes,
    claimDetails,
    showTreatingSpecialist,
    openEditModal,
    submitClaimDetails,
}: InnerProps) => {
    function edit() {
        openEditModal({
            modalContent: (
                <ClaimDetailsFormModal
                    recordDetailView={
                        <ClaimDetailsForm
                            heading="Edit Claim Event Details"
                            showTreatingSpecialist={showTreatingSpecialist}
                        />
                    }
                    records={[claimDetails]}
                    updateRecord={record => submitClaimDetails(record)}
                />
            ),
            modalData: {
                recordIndex: 0,
            },
        });
    }
    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Claim Event Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} className={classes.rightAlign}>
                        <Tooltip title="Edit Claim Details">
                            <Button id="EditClaimDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Date of claim event"
                            value={getDisplayDate(claimDetails.dateOfEvent)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Date claim reported"
                            value={getDisplayDate(claimDetails.dateClaimReported)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Medical condition type"
                            value={claimDetails.medicalConditionType}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {claimDetails.reportedToPolice && (
                            <LabeledValue
                                label="Reported to the police"
                                value={claimDetails.reportedToPolice}
                            />
                        )}
                    </Grid>
                    {claimDetails.reportedToPolice === 'Yes' && (
                        <React.Fragment>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Police station"
                                    value={claimDetails.policeStation}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue label="Case number" value={claimDetails.caseNumber} />
                            </Grid>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Accident details"
                                    value={claimDetails.accidentDetails}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={3}>
                        <LabeledValue label="Natural/unnatural" value={claimDetails.condition} />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Major claim cause"
                            value={claimDetails.majorClaimCause || claimDetails.currentCauseOfClaim}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Claim category"
                            value={
                                claimDetails.claimCategory === 'Other'
                                    ? claimDetails.claimCategoryDesc
                                    : claimDetails.claimCategory
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Claim condition"
                            value={
                                claimDetails.claimCondition === 'Other'
                                    ? claimDetails.claimConditionDesc
                                    : claimDetails.claimCondition
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Detailed claim condition"
                            value={
                                claimDetails.detailedClaimCondition === 'Other'
                                    ? claimDetails.detailedClaimConditionDesc
                                    : claimDetails.detailedClaimCondition
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledValue label="Comments on cause" value={claimDetails.comments} />
                    </Grid>
                    {(claimDetails.currentTreatmentPlan ||
                        claimDetails.descriptionOfCurrentTreatmentPlan ||
                        claimDetails.descriptionOfCurrentMedication) && (
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.marginTop}>
                                Treatment details
                            </Typography>
                        </Grid>
                    )}
                    {claimDetails.currentTreatmentPlan && (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <LabeledValue
                                    label="Current high level treatment plan"
                                    value={claimDetails.currentTreatmentPlan}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {claimDetails.currentTreatmentPlan === 'Other' && (
                                    <LabeledValue
                                        label="Other description"
                                        value={claimDetails.highLevelDescriptionOfTreatmentOther}
                                    />
                                )}
                            </Grid>
                        </React.Fragment>
                    )}
                    {claimDetails.descriptionOfCurrentTreatmentPlan && (
                        <Grid item xs={6}>
                            <LabeledValue
                                label="Detailed description of current treatment plan"
                                value={claimDetails.descriptionOfCurrentTreatmentPlan}
                            />
                        </Grid>
                    )}
                    {claimDetails.descriptionOfCurrentMedication && (
                        <Grid item xs={6}>
                            <LabeledValue
                                label="Description of current medication"
                                value={claimDetails.descriptionOfCurrentMedication}
                            />
                        </Grid>
                    )}
                    {showTreatingSpecialist && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography className={classes.marginTop} variant="subtitle2">
                                    Treating Specialist Details
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Initials"
                                    value={claimDetails.treatingSpecialistInitials}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Name"
                                    value={claimDetails.treatingSpecialistName}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Surname"
                                    value={claimDetails.treatingSpecialistSurname}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Telephone Number"
                                    value={claimDetails.treatingSpecialistTelephoneNumber}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Registration Number"
                                    value={claimDetails.treatingSpecialistRegistrationNumber}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    claimDetails: getClaimDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openEditModal: record => dispatch(openEditModalAction(record)),
    submitClaimDetails: record => dispatch(submitClaimDetailsAction(record)),
});
export const ClaimDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimDetailsView));
