export * from './detailed-claim-condition-dread';
export const DetailedClaimConditions = [
    {
        value:
            'Activities of Daily Living catch-all - Permanent inability to perform 1 or more Basic ADLs, and permanent inability to perform 2 or more Instrumental ADLs',
        label:
            'Activities of Daily Living catch-all - Permanent inability to perform 1 or more Basic ADLs, and permanent inability to perform 2 or more Instrumental ADLs',
        Category: 'Activities of Daily Living catch-all',
    },
    {
        value:
            'Activities of Daily Living catch-all - Permanent inability to perform 3 or more Basic ADLs, and permanent inability to perform 4 or more Instrumental ADLs',
        label:
            'Activities of Daily Living catch-all - Permanent inability to perform 3 or more Basic ADLs, and permanent inability to perform 4 or more Instrumental ADLs',
        Category: 'Activities of Daily Living catch-all',
    },
    {
        value:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 2 weeks.',
        label:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 2 weeks.',
        Category: 'Anaemia',
    },
    {
        value:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 6 weeks',
        label:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 6 weeks',
        Category: 'Anaemia',
    },
    {
        value:
            'Anorectal disease - Complete and permanent faecal incontinence not amenable to colostomy',
        label:
            'Anorectal disease - Complete and permanent faecal incontinence not amenable to colostomy',
        Category: 'Anorectal disease',
    },
    {
        value:
            'Anorectal disease - Complete and permanent faecal incontinence treated with permanent colostomy',
        label:
            'Anorectal disease - Complete and permanent faecal incontinence treated with permanent colostomy',
        Category: 'Anorectal disease',
    },
    {
        value: 'Anorectal disease - Partial but permanent faecal incontinence',
        label: 'Anorectal disease - Partial but permanent faecal incontinence',
        Category: 'Anorectal disease',
    },
    {
        value:
            'Aphasia/dysphasia - Complete inability to comprehend language symbols as a result of injury to, or disease of the brain as confirmed by a neurologist or other appropriate specialist.',
        label:
            'Aphasia/dysphasia - Complete inability to comprehend language symbols as a result of injury to, or disease of the brain as confirmed by a neurologist or other appropriate specialist.',
        Category: 'Aphasia/dysphasia',
    },
    {
        value:
            'Aphasia/dysphasia - Partial inability to comprehend language symbols as a result of injury to, or disease of the brain as confirmed by a neurologist or other appropriate specialist',
        label:
            'Aphasia/dysphasia - Partial inability to comprehend language symbols as a result of injury to, or disease of the brain as confirmed by a neurologist or other appropriate specialist',
        Category: 'Aphasia/dysphasia',
    },
    {
        value:
            'Arrhythmias - Symptoms present must include: palpitations, light-headedness, presyncope or sustained reduced cardiac output despite appropriate surgical treatment or where surgery is contraindicated',
        label:
            'Arrhythmias - Symptoms present must include: palpitations, light-headedness, presyncope or sustained reduced cardiac output despite appropriate surgical treatment or where surgery is contraindicated',
        Category: 'Arrhythmias',
    },
    {
        value: 'Biliary tract - With recurrent cholangitis and bilirubin greater than 34μmol/l',
        label: 'Biliary tract - With recurrent cholangitis and bilirubin greater than 34μmol/l',
        Category: 'Biliary tract',
    },
    {
        value: 'Biliary tract - With recurrent cholangitis and bilirubin greater than 50μmol/l',
        label: 'Biliary tract - With recurrent cholangitis and bilirubin greater than 50μmol/l',
        Category: 'Biliary tract',
    },
    {
        value: 'Bladder - Surgical removal of the bladder',
        label: 'Bladder - Surgical removal of the bladder',
        Category: 'Bladder',
    },
    {
        value:
            'Bladder - Total urinary incontinence, loss of reflex or voluntary urine control despite medical and surgical treatment',
        label:
            'Bladder - Total urinary incontinence, loss of reflex or voluntary urine control despite medical and surgical treatment',
        Category: 'Bladder',
    },
    {
        value:
            'Cancer - Cancer which has resulted in documented permanent end-organ dysfunction or damage - assessed based on the affected end-organ system.',
        label:
            'Cancer - Cancer which has resulted in documented permanent end-organ dysfunction or damage - assessed based on the affected end-organ system.',
        Category: 'Cancer',
    },
    {
        value: 'Cancer and leukaemia - Diagnosis of acute myoblastic leukaemia with poor prognosis',
        label: 'Cancer and leukaemia - Diagnosis of acute myoblastic leukaemia with poor prognosis',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Failure of leukaemia treatment',
        label: 'Cancer and leukaemia - Failure of leukaemia treatment',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Relapse of leukaemia',
        label: 'Cancer and leukaemia - Relapse of leukaemia',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Requiring a bone marrow transplant for leukaemia',
        label: 'Cancer and leukaemia - Requiring a bone marrow transplant for leukaemia',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Stage IV',
        label: 'Cancer and leukaemia - Stage IV',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cardiomyopathy - EF 35-40%',
        label: 'Cardiomyopathy - EF 35-40%',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - EF less than 35%',
        label: 'Cardiomyopathy - EF less than 35%',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - EF less than 45%',
        label: 'Cardiomyopathy - EF less than 45%',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - METS 2-5',
        label: 'Cardiomyopathy - METS 2-5',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - METS 5 or less',
        label: 'Cardiomyopathy - METS 5 or less',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - METS less than 2',
        label: 'Cardiomyopathy - METS less than 2',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - NYHA III',
        label: 'Cardiomyopathy - NYHA III',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - NYHA III or greater',
        label: 'Cardiomyopathy - NYHA III or greater',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - NYHA IV',
        label: 'Cardiomyopathy - NYHA IV',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - Recovered from surgery for hypertrophic cardiomyopathy',
        label: 'Cardiomyopathy - Recovered from surgery for hypertrophic cardiomyopathy',
        Category: 'Cardiomyopathy',
    },
    {
        value:
            'Cardiovascular system - Receiving optimal treatment, but still symptomatic NYHA class 4, with METS < 3 or EF < 40%',
        label:
            'Cardiovascular system - Receiving optimal treatment, but still symptomatic NYHA class 4, with METS < 3 or EF < 40%',
        Category: 'Cardiovascular system',
    },
    {
        value:
            'Clotting disorders - Bleeds or clots which have resulted in documented permanent end-organ dysfunction or damage in spite of surgical or medical treatment',
        label:
            'Clotting disorders - Bleeds or clots which have resulted in documented permanent end-organ dysfunction or damage in spite of surgical or medical treatment',
        Category: 'Clotting disorders',
    },
    {
        value:
            'Coronary artery disease - Assessed on occurrence of a myocardial infarction (heart attack), coronary artery bypass graft surgery (CABG) or angioplasty with or without stenting',
        label:
            'Coronary artery disease - Assessed on occurrence of a myocardial infarction (heart attack), coronary artery bypass graft surgery (CABG) or angioplasty with or without stenting',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - EF less than 45%',
        label: 'Coronary artery disease - EF less than 45%',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - METS 5 or less',
        label: 'Coronary artery disease - METS 5 or less',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - NYHA III or greater',
        label: 'Coronary artery disease - NYHA III or greater',
        Category: 'Coronary artery disease',
    },
    {
        value:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Inability to swallow, requiring regular suctioning to prevent aspiration or choking',
        label:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Inability to swallow, requiring regular suctioning to prevent aspiration or choking',
        Category: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
    },
    {
        value:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Moderate to severe dysarthria or dysphasia, nasal regurgitation and aspiration',
        label:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Moderate to severe dysarthria or dysphasia, nasal regurgitation and aspiration',
        Category: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
    },
    {
        value:
            'Cranial nerve V (trigeminal nerve) - Severe uncontrolled unilateral or bilateral facial neuralgic pain affecting either speech or eating',
        label:
            'Cranial nerve V (trigeminal nerve) - Severe uncontrolled unilateral or bilateral facial neuralgic pain affecting either speech or eating',
        Category: 'Cranial nerve V (trigeminal nerve)',
    },
    {
        value:
            'Cranial nerve VII (facial nerve) - Unilateral or bilateral facial nerve paralysis with the inability to control eyelid closure',
        label:
            'Cranial nerve VII (facial nerve) - Unilateral or bilateral facial nerve paralysis with the inability to control eyelid closure',
        Category: 'Cranial nerve VII (facial nerve)',
    },
    {
        value:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and presence of symptoms listed below leading to the inability to climb stairs or walk unaided by another person, with abnormal findings on electronystagmography or videonystagmography.                                                                                                                                  * Frequent nausea and vomiting documented weekly;                                                                            * Vertigo with uncoordinated movement; and                                                                                          * Inability to balance.',
        label:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and presence of symptoms listed below leading to the inability to climb stairs or walk unaided by another person, with abnormal findings on electronystagmography or videonystagmography.                                                                                                                                  * Frequent nausea and vomiting documented weekly;                                                                            * Vertigo with uncoordinated movement; and                                                                                          * Inability to balance.',
        Category: 'Cranial nerve VIII (vestibulocochlear nerve)',
    },
    {
        value:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and the presence of the following symptoms:frequent nausea and vomiting documented weekly; and vertigo with uncoordinated movement; and inability to balance.',
        label:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and the presence of the following symptoms:frequent nausea and vomiting documented weekly; and vertigo with uncoordinated movement; and inability to balance.',
        Category: 'Cranial nerve VIII (vestibulocochlear nerve)',
    },
    {
        value:
            'Diplegia / paraplegia - Total loss or total loss of use of two lower limbs due to a central nervous system disease, disorder or injury',
        label:
            'Diplegia / paraplegia - Total loss or total loss of use of two lower limbs due to a central nervous system disease, disorder or injury',
        Category: 'Diplegia / paraplegia',
    },
    {
        value:
            'Diplegia / paraplegia - Total loss or total loss of use of two upper limbs due to a central nervous system disease, disorder or injury',
        label:
            'Diplegia / paraplegia - Total loss or total loss of use of two upper limbs due to a central nervous system disease, disorder or injury',
        Category: 'Diplegia / paraplegia',
    },
    {
        value:
            'Diseases of the aorta - Symptoms include intermittent caludication, inappropriate limb fatigue and pain, and thromboembolic disease (gangrene)',
        label:
            'Diseases of the aorta - Symptoms include intermittent caludication, inappropriate limb fatigue and pain, and thromboembolic disease (gangrene)',
        Category: 'Diseases of the aorta',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with NYHA III',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with NYHA III',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with right heart failure',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with NYHA IV',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with NYHA IV',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with right heart failure',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with NYHA III',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with NYHA III',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with right heart failure',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Episodic loss of consciousness - 12 or more Grand Mal seizures per month for 4 or more consecutive months in spite of sustained optimal treatment and documented compliance of that treatment',
        label:
            'Episodic loss of consciousness - 12 or more Grand Mal seizures per month for 4 or more consecutive months in spite of sustained optimal treatment and documented compliance of that treatment',
        Category: 'Episodic loss of consciousness',
    },
    {
        value:
            'Episodic loss of consciousness - 6 or more Grand Mal seizures per month for 4 or more consecutive months in spite of sustained optimal treatment and documented compliance to that treatment',
        label:
            'Episodic loss of consciousness - 6 or more Grand Mal seizures per month for 4 or more consecutive months in spite of sustained optimal treatment and documented compliance to that treatment',
        Category: 'Episodic loss of consciousness',
    },
    {
        value:
            'Episodic loss of consciousness - At least 3 documented episodes of Status Epilepticus requiring emergency medical treatment within the last 12 months',
        label:
            'Episodic loss of consciousness - At least 3 documented episodes of Status Epilepticus requiring emergency medical treatment within the last 12 months',
        Category: 'Episodic loss of consciousness',
    },
    {
        value:
            'Face - Disfigurement due to the destruction of the bones or tissues and absence of normal anatomic part or area of the face (e.g. the nose) with resulting cosmetic deformity',
        label:
            'Face - Disfigurement due to the destruction of the bones or tissues and absence of normal anatomic part or area of the face (e.g. the nose) with resulting cosmetic deformity',
        Category: 'Face',
    },
    {
        value:
            'Gait disorders/motor coordination - Difficulty with grasping, pincer grip, fine or gross motor coordination or grip strength',
        label:
            'Gait disorders/motor coordination - Difficulty with grasping, pincer grip, fine or gross motor coordination or grip strength',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value:
            'Gait disorders/motor coordination - Difficulty with standing, maintaining a standing position and needing assistance with walking',
        label:
            'Gait disorders/motor coordination - Difficulty with standing, maintaining a standing position and needing assistance with walking',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions and documented previous falls and inability to stand, walk, stoop, squat, kneel, climb stairs',
        label:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions and documented previous falls and inability to stand, walk, stoop, squat, kneel, climb stairs',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions; and inability to grasp, pincer grip and inability with fine or gross motor coordination or grip strength',
        label:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions; and inability to grasp, pincer grip and inability with fine or gross motor coordination or grip strength',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value:
            'Hearing - In both ears between 70-87dB (per ear) in 2 measurements at least 6 months apart',
        label:
            'Hearing - In both ears between 70-87dB (per ear) in 2 measurements at least 6 months apart',
        Category: 'Hearing',
    },
    {
        value:
            'Hearing - In both ears greater than 87dB (per ear) in 2 measurements at least 6 months apart',
        label:
            'Hearing - In both ears greater than 87dB (per ear) in 2 measurements at least 6 months apart',
        Category: 'Hearing',
    },
    {
        value:
            'Hearing - In one ear of greater than 70dB in 2 measurements at least 6 months apart',
        label:
            'Hearing - In one ear of greater than 70dB in 2 measurements at least 6 months apart',
        Category: 'Hearing',
    },
    {
        value: 'Heart transplant - A heart transplant',
        label: 'Heart transplant - A heart transplant',
        Category: 'Heart transplant',
    },
    {
        value: 'Heart transplant - Heart transplant',
        label: 'Heart transplant - Heart transplant',
        Category: 'Heart transplant',
    },
    {
        value:
            'Hemiplegia - Total loss or total loss of use of two limbs on the same side of the body due to a central nervous system disease, disorder or injury',
        label:
            'Hemiplegia - Total loss or total loss of use of two limbs on the same side of the body due to a central nervous system disease, disorder or injury',
        Category: 'Hemiplegia',
    },
    {
        value:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications (strangulations, obstructions, ischaemia and gangrene)',
        label:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications (strangulations, obstructions, ischaemia and gangrene)',
        Category: 'Hernia',
    },
    {
        value:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications, as specified in the policy document, and; weight loss resulting in a BMI of less than 14.5.',
        label:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications, as specified in the policy document, and; weight loss resulting in a BMI of less than 14.5.',
        Category: 'Hernia',
    },
    {
        value:
            'Hypertensive cardiovascular disease - Documented Stage 3 hypertension despite treatment over at least a 3 month period and left ventricular hypertrophy',
        label:
            'Hypertensive cardiovascular disease - Documented Stage 3 hypertension despite treatment over at least a 3 month period and left ventricular hypertrophy',
        Category: 'Hypertensive cardiovascular disease',
    },
    {
        value: 'Hypertensive cardiovascular disease - Renal system impairment with GFR 24-40ml/min',
        label: 'Hypertensive cardiovascular disease - Renal system impairment with GFR 24-40ml/min',
        Category: 'Hypertensive cardiovascular disease',
    },
    {
        value:
            'Hypertensive cardiovascular disease - Renal system impairment with GFR less than 24ml/min with central nervous system damage',
        label:
            'Hypertensive cardiovascular disease - Renal system impairment with GFR less than 24ml/min with central nervous system damage',
        Category: 'Hypertensive cardiovascular disease',
    },
    {
        value:
            'Hypertensive cardiovascular disease - Renal system impairment with GFR less than 24ml/min with NYHA IV',
        label:
            'Hypertensive cardiovascular disease - Renal system impairment with GFR less than 24ml/min with NYHA IV',
        Category: 'Hypertensive cardiovascular disease',
    },
    {
        value:
            'Hypertensive cardiovascular disease - Renal system impairment with GFR less than 40ml/min',
        label:
            'Hypertensive cardiovascular disease - Renal system impairment with GFR less than 40ml/min',
        Category: 'Hypertensive cardiovascular disease',
    },
    {
        value: 'Impairment of consciousness - Irreversible coma requiring total medical support',
        label: 'Impairment of consciousness - Irreversible coma requiring total medical support',
        Category: 'Impairment of consciousness',
    },
    {
        value:
            'Impairment of consciousness - Stabilised Glasgow Coma Scale score of less than 9 for at least five days',
        label:
            'Impairment of consciousness - Stabilised Glasgow Coma Scale score of less than 9 for at least five days',
        Category: 'Impairment of consciousness',
    },
    {
        value: 'Inability to work',
        label: 'Inability to work',
        Category: 'Inability to work',
    },
    {
        value:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin 28-35mg/L; - INR 1.71-2.20; - Bilirubin 34-50μmol/l; - Ascites.',
        label:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin 28-35mg/L; - INR 1.71-2.20; - Bilirubin 34-50μmol/l; - Ascites.',
        Category: 'Liver disease',
    },
    {
        value:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin less than 28mg/L; - INR greater than 2.20; - Bilirubin 34-50μmol/l; - Ascites.',
        label:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin less than 28mg/L; - INR greater than 2.20; - Bilirubin 34-50μmol/l; - Ascites.',
        Category: 'Liver disease',
    },
    {
        value:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin less than 28mg/L; - INR greater than 2.20; - Bilirubin greater than 50μmol/l; - Ascites.',
        label:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin less than 28mg/L; - INR greater than 2.20; - Bilirubin greater than 50μmol/l; - Ascites.',
        Category: 'Liver disease',
    },
    {
        value:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin less than 35mg/L; - INR greater than 1.71; - Bilirubin greater than 34μmol/l; - Ascites.',
        label:
            'Liver disease - Abnormal liver function tests, as evidenced by at least two of the following: - Albumin less than 35mg/L; - INR greater than 1.71; - Bilirubin greater than 34μmol/l; - Ascites.',
        Category: 'Liver disease',
    },
    {
        value:
            'Liver disease - Persistent jaundice and bleeding varices and central nervous system manifestations of hepatoxicity including history of or on-going encephalopathy; and severe symptoms and signs uncontrolled by medical or surgical treatment; as evidenced by at least two of the following: - Albumin less than 28mg/L; - INR greater than 2.20; - Bilirubin greater than 50μmol/l; - Ascites.',
        label:
            'Liver disease - Persistent jaundice and bleeding varices and central nervous system manifestations of hepatoxicity including history of or on-going encephalopathy; and severe symptoms and signs uncontrolled by medical or surgical treatment; as evidenced by at least two of the following: - Albumin less than 28mg/L; - INR greater than 2.20; - Bilirubin greater than 50μmol/l; - Ascites.',
        Category: 'Liver disease',
    },
    {
        value:
            'Lower gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment; and weight loss resulting in a BMI of less than 16 due to disease.',
        label:
            'Lower gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment; and weight loss resulting in a BMI of less than 16 due to disease.',
        Category: 'Lower gastrointestinal tract',
    },
    {
        value:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        label:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        Category: 'Lower gastrointestinal tract',
    },
    {
        value:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        label:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        Category: 'Lower gastrointestinal tract',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Bilateral involvement requiring use of assistive devices for hand function',
        label:
            'Lumbar, thoracic and cervical spine - Bilateral involvement requiring use of assistive devices for hand function',
        Category: 'Lumbar, thoracic and cervical spine',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: cauda equine syndrome',
        label:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: cauda equine syndrome',
        Category: 'Lumbar, thoracic and cervical spine',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: unilateral involvement requiring use of assistive devices for hand function',
        label:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: unilateral involvement requiring use of assistive devices for hand function',
        Category: 'Lumbar, thoracic and cervical spine',
    },
    {
        value:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed',
        label:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed',
        Category: 'Neurological impairment of respiration',
    },
    {
        value:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed but not requiring ventilator assistance',
        label:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed but not requiring ventilator assistance',
        Category: 'Neurological impairment of respiration',
    },
    {
        value:
            'Neurological impairment of respiration - No capacity for spontaneous respiration and requiring ventilation',
        label:
            'Neurological impairment of respiration - No capacity for spontaneous respiration and requiring ventilation',
        Category: 'Neurological impairment of respiration',
    },
    {
        value:
            'Organic brain disorders and dementia - Clinical dementia due to an organic cause with two consecutive CDR 1.0 ratings and a Minimum Mental Score of 18 or less with the assessments being done at least two months apart',
        label:
            'Organic brain disorders and dementia - Clinical dementia due to an organic cause with two consecutive CDR 1.0 ratings and a Minimum Mental Score of 18 or less with the assessments being done at least two months apart',
        Category: 'Organic brain disorders and dementia',
    },
    {
        value:
            'Organic brain disorders and dementia - Clinical dementia due to an organic cause with two consecutive CDR 2.0 ratings and a Mini Mental Score of 15 or less with the assessments being done at least two months apart.',
        label:
            'Organic brain disorders and dementia - Clinical dementia due to an organic cause with two consecutive CDR 2.0 ratings and a Mini Mental Score of 15 or less with the assessments being done at least two months apart.',
        Category: 'Organic brain disorders and dementia',
    },
    {
        value:
            'Organic brain disorders and dementia - Clinical dementia due to an organic cause with two consecutive CDR 3.0 ratings and a Mini Mental Score of 13 or less with the assessments being done at least two months apart.',
        label:
            'Organic brain disorders and dementia - Clinical dementia due to an organic cause with two consecutive CDR 3.0 ratings and a Mini Mental Score of 13 or less with the assessments being done at least two months apart.',
        Category: 'Organic brain disorders and dementia',
    },
    {
        value: 'Paralysis - Hemiplegia',
        label: 'Paralysis - Hemiplegia',
        Category: 'Paralysis',
    },
    {
        value: 'Paralysis - Paraplegia',
        label: 'Paralysis - Paraplegia',
        Category: 'Paralysis',
    },
    {
        value: 'Paralysis - Quadriplegia',
        label: 'Paralysis - Quadriplegia',
        Category: 'Paralysis',
    },
    {
        value: 'Pericardial disease - EF 35-40%',
        label: 'Pericardial disease - EF 35-40%',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - EF less than 35%',
        label: 'Pericardial disease - EF less than 35%',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - EF less than 45%',
        label: 'Pericardial disease - EF less than 45%',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - METS 2-5',
        label: 'Pericardial disease - METS 2-5',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - METS 5 or less',
        label: 'Pericardial disease - METS 5 or less',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - METS less than 2',
        label: 'Pericardial disease - METS less than 2',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - NYHA III',
        label: 'Pericardial disease - NYHA III',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - NYHA III or greater',
        label: 'Pericardial disease - NYHA III or greater',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - NYHA IV',
        label: 'Pericardial disease - NYHA IV',
        Category: 'Pericardial disease',
    },
    {
        value:
            'Peripheral arterial disease - Abnormal diminished pulse on Doppler Readings and Cold leg; and Pain on exercise',
        label:
            'Peripheral arterial disease - Abnormal diminished pulse on Doppler Readings and Cold leg; and Pain on exercise',
        Category: 'Peripheral arterial disease',
    },
    {
        value: 'Peripheral arterial disease - Gangrene',
        label: 'Peripheral arterial disease - Gangrene',
        Category: 'Peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - Persistent ischaemia with no detectable pulse on Doppler readings',
        label:
            'Peripheral arterial disease - Persistent ischaemia with no detectable pulse on Doppler readings',
        Category: 'Peripheral arterial disease',
    },
    {
        value: 'Peripheral arterial disease - Severe vascular ulceration',
        label: 'Peripheral arterial disease - Severe vascular ulceration',
        Category: 'Peripheral arterial disease',
    },
    {
        value: 'Peripheral venous disease - Severe, deep and widespread vascular ulceration',
        label: 'Peripheral venous disease - Severe, deep and widespread vascular ulceration',
        Category: 'Peripheral venous disease',
    },
    {
        value:
            'Quadriplegia - Total loss or total loss of use of all four limbs due to a central nervous system disease, disorder or injury',
        label:
            'Quadriplegia - Total loss or total loss of use of all four limbs due to a central nervous system disease, disorder or injury',
        Category: 'Quadriplegia',
    },
    {
        value:
            'Schizophrenia/other pyschotic disorders - Institutionalised at least 3 times in the last 12 months with each admission lasting for longer than 6 weeks; and GAF less than 40.',
        label:
            'Schizophrenia/other pyschotic disorders - Institutionalised at least 3 times in the last 12 months with each admission lasting for longer than 6 weeks; and GAF less than 40.',
        Category: 'Schizophrenia/other pyschotic disorders',
    },
    {
        value:
            'Schizophrenia/other pyschotic disorders - Requiring permanent institutionalisation or permanent caregiver; and GAF less than 30',
        label:
            'Schizophrenia/other pyschotic disorders - Requiring permanent institutionalisation or permanent caregiver; and GAF less than 30',
        Category: 'Schizophrenia/other pyschotic disorders',
    },
    {
        value:
            'Sleep disorders - Documented prolonged sleep disorder with interruption to, or shortening of normal sleep cycles, resulting in cognitive deterioration over time; and persistent inappropriate daytime fatigue and impaired concentration and documented cataplexy occurring 3 or more times daily and completely housebound.',
        label:
            'Sleep disorders - Documented prolonged sleep disorder with interruption to, or shortening of normal sleep cycles, resulting in cognitive deterioration over time; and persistent inappropriate daytime fatigue and impaired concentration and documented cataplexy occurring 3 or more times daily and completely housebound.',
        Category: 'Sleep disorders',
    },
    {
        value:
            'Sleep disorders - Documented prolonged sleep disorder with interruption to, or shortening of normal sleep cycles, resulting in cognitive deterioration over time; and persistent inappropriate daytime fatigue and impaired concentration and documented cataplexy occurring daily',
        label:
            'Sleep disorders - Documented prolonged sleep disorder with interruption to, or shortening of normal sleep cycles, resulting in cognitive deterioration over time; and persistent inappropriate daytime fatigue and impaired concentration and documented cataplexy occurring daily',
        Category: 'Sleep disorders',
    },
    {
        value:
            'Stroke (cerebrovascular incident) - Either four of the following: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, Moderate cognitive problems, or Homonymous Hemianopia"',
        label:
            'Stroke (cerebrovascular incident) - Either four of the following: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, Moderate cognitive problems, or Homonymous Hemianopia"',
        Category: 'Stroke (cerebrovascular incident)',
    },
    {
        value:
            'Stroke (cerebrovascular incident) - The inability to perform any one of the following categories: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, or Moderate cognitive problems',
        label:
            'Stroke (cerebrovascular incident) - The inability to perform any one of the following categories: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, or Moderate cognitive problems',
        Category: 'Stroke (cerebrovascular incident)',
    },
    {
        value:
            'The endocrine system - Symptoms and signs of abnormality of the endocrine system leading to targer end-organ damage despite appropriate surgical and medical treatment including replacement or blockades',
        label:
            'The endocrine system - Symptoms and signs of abnormality of the endocrine system leading to targer end-organ damage despite appropriate surgical and medical treatment including replacement or blockades',
        Category: 'The endocrine system',
    },
    {
        value:
            'The limbs - At least 2 of the following: - Hand at MP joint, Arm below the elbow, - Arm above the elbow, - Leg through the hip, - Leg above the knee, - Leg below the knee',
        label:
            'The limbs - At least 2 of the following: - Hand at MP joint, Arm below the elbow, - Arm above the elbow, - Leg through the hip, - Leg above the knee, - Leg below the knee',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Hemipelvectomy',
        label: 'The limbs - Hemipelvectomy',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use foot',
        label: 'The limbs - Loss of or loss of use foot',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of arm below the elbow',
        label: 'The limbs - Loss of or loss of use of arm below the elbow',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of arm below the shoulder',
        label: 'The limbs - Loss of or loss of use of arm below the shoulder',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of hand at MP joint',
        label: 'The limbs - Loss of or loss of use of hand at MP joint',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg above the kness',
        label: 'The limbs - Loss of or loss of use of leg above the kness',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg below the knee',
        label: 'The limbs - Loss of or loss of use of leg below the knee',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg through the hip',
        label: 'The limbs - Loss of or loss of use of leg through the hip',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of thumb through MP joint',
        label: 'The limbs - Loss of or loss of use of thumb through MP joint',
        Category: 'The limbs',
    },
    {
        value: 'The respiratory system - DCO less than 40% of predicted',
        label: 'The respiratory system - DCO less than 40% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - DCO less than 60% of predicted',
        label: 'The respiratory system - DCO less than 60% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - DCO of 40 - 59% of predicted',
        label: 'The respiratory system - DCO of 40 - 59% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FEV1 less than 40% of predicted',
        label: 'The respiratory system - FEV1 less than 40% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FEV1 less than 60% of predicted',
        label: 'The respiratory system - FEV1 less than 60% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FEV1 of 40 - 59% of predicted',
        label: 'The respiratory system - FEV1 of 40 - 59% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC less than 40% of predicted',
        label: 'The respiratory system - FVC less than 40% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC less than 60% of predicted',
        label: 'The respiratory system - FVC less than 60% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC of 40 - 49% of predicted',
        label: 'The respiratory system - FVC of 40 - 49% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC of 50 - 59% of predicted',
        label: 'The respiratory system - FVC of 50 - 59% of predicted',
        Category: 'The respiratory system',
    },
    {
        value:
            'The skin - Extensive permanent involvement of an upper and lower limb including the sole of one foot and the palm of one hand',
        label:
            'The skin - Extensive permanent involvement of an upper and lower limb including the sole of one foot and the palm of one hand',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral lower limbs including the soles of both feet',
        label:
            'The skin - Extensive permanent involvement of the bilateral lower limbs including the soles of both feet',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands',
        label:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands; and restriction of joint mobility',
        label:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands; and restriction of joint mobility',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Third degree burns involving more than 20% of the total body surface area',
        label:
            'The skin - Third degree burns involving more than 20% of the total body surface area',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Third degree burns involving more than 30% of the total body surface area',
        label:
            'The skin - Third degree burns involving more than 30% of the total body surface area',
        Category: 'The skin',
    },
    {
        value: 'The visual system - Diabetic retinopathy grade III',
        label: 'The visual system - Diabetic retinopathy grade III',
        Category: 'The visual system',
    },
    {
        value: 'The visual system - Diabetic retinopathy grade IV',
        label: 'The visual system - Diabetic retinopathy grade IV',
        Category: 'The visual system',
    },
    {
        value: 'The visual system - Irreversible hemianopia in both eyes',
        label: 'The visual system - Irreversible hemianopia in both eyes',
        Category: 'The visual system',
    },
    {
        value: 'The visual system - Irreversible hemianopia in one eye',
        label: 'The visual system - Irreversible hemianopia in one eye',
        Category: 'The visual system',
    },
    {
        value:
            'The visual system - Irreversible visual acuity impairment, not correctable by operation resulting in a Snellen rating of less than 20/200 in both eyes',
        label:
            'The visual system - Irreversible visual acuity impairment, not correctable by operation resulting in a Snellen rating of less than 20/200 in both eyes',
        Category: 'The visual system',
    },
    {
        value:
            'The visual system - Irreversible visual acuity impairment, not correctable by operation, resulting in a Snellen rating of less than 20/125 in both eyes',
        label:
            'The visual system - Irreversible visual acuity impairment, not correctable by operation, resulting in a Snellen rating of less than 20/125 in both eyes',
        Category: 'The visual system',
    },
    {
        value:
            'The visual system - Irreversible visual acuity impairment, not correctable by operation, resulting in a Snellen rating of less than 20/200 in one eye',
        label:
            'The visual system - Irreversible visual acuity impairment, not correctable by operation, resulting in a Snellen rating of less than 20/200 in one eye',
        Category: 'The visual system',
    },
    {
        value: 'Upper air passages - Dyspnoea at rest and NYHA IV',
        label: 'Upper air passages - Dyspnoea at rest and NYHA IV',
        Category: 'Upper air passages',
    },
    {
        value: 'Upper air passages - Dyspnoea at rest and respiratory ventilation is required',
        label: 'Upper air passages - Dyspnoea at rest and respiratory ventilation is required',
        Category: 'Upper air passages',
    },
    {
        value: 'Upper air passages - Dyspnoea on moderate effort and NYHA III',
        label: 'Upper air passages - Dyspnoea on moderate effort and NYHA III',
        Category: 'Upper air passages',
    },
    {
        value: 'Upper air passages - Dyspnoea on moderate effort and NYHA III or greater',
        label: 'Upper air passages - Dyspnoea on moderate effort and NYHA III or greater',
        Category: 'Upper air passages',
    },
    {
        value:
            'Upper gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment and weight loss resulting in a BMI of less than 16 due to disease.',
        label:
            'Upper gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment and weight loss resulting in a BMI of less than 16 due to disease.',
        Category: 'Upper gastrointestinal tract',
    },
    {
        value:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        label:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        Category: 'Upper gastrointestinal tract',
    },
    {
        value:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        label:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        Category: 'Upper gastrointestinal tract',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR 24-40ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR 24-40ml/min despite treatment',
        Category: 'Upper urinary tract',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 24ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 24ml/min despite treatment',
        Category: 'Upper urinary tract',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 40ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 40ml/min despite treatment',
        Category: 'Upper urinary tract',
    },
    {
        value: 'Upper urinary tract - Requires ongoing dialysis',
        label: 'Upper urinary tract - Requires ongoing dialysis',
        Category: 'Upper urinary tract',
    },
    {
        value:
            'Urethra - Urethral dysfunction and loss of voluntary urine control; and requiring an indwelling catheter.',
        label:
            'Urethra - Urethral dysfunction and loss of voluntary urine control; and requiring an indwelling catheter.',
        Category: 'Urethra',
    },
    {
        value: 'Valvular heart disease - EF 35-40%',
        label: 'Valvular heart disease - EF 35-40%',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - EF less than 35%',
        label: 'Valvular heart disease - EF less than 35%',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - EF less than 45%',
        label: 'Valvular heart disease - EF less than 45%',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - METS 2-5',
        label: 'Valvular heart disease - METS 2-5',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - METS 5 or less',
        label: 'Valvular heart disease - METS 5 or less',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - METS less than 2',
        label: 'Valvular heart disease - METS less than 2',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - NYHA III',
        label: 'Valvular heart disease - NYHA III',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - NYHA III or greater',
        label: 'Valvular heart disease - NYHA III or greater',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - NYHA IV',
        label: 'Valvular heart disease - NYHA IV',
        Category: 'Valvular heart disease',
    },
    {
        value:
            'Vestibular disorders - With frequent attacks of nausea, vomiting, vertigo and dizziness lasting more than 3 hours per episode, with at least 10 episodes per month.',
        label:
            'Vestibular disorders - With frequent attacks of nausea, vomiting, vertigo and dizziness lasting more than 3 hours per episode, with at least 10 episodes per month.',
        Category: 'Vestibular disorders',
    },
    {
        value:
            'Vestibular disorders - With permanent failure of any of the following functions, namely: walking, standing, stooping, squatting, kneeling, climbing stairs; and completely housebound',
        label:
            'Vestibular disorders - With permanent failure of any of the following functions, namely: walking, standing, stooping, squatting, kneeling, climbing stairs; and completely housebound',
        Category: 'Vestibular disorders',
    },
    {
        value: 'Voice/speech impairment - Failure of all three functions',
        label: 'Voice/speech impairment - Failure of all three functions',
        Category: 'Voice/speech impairment',
    },
    {
        value: 'Voice/speech impairment - Failure of all three moderate functions',
        label: 'Voice/speech impairment - Failure of all three moderate functions',
        Category: 'Voice/speech impairment',
    },
    {
        value: 'Voice/speech impairment - Failure of all three severe functions',
        label: 'Voice/speech impairment - Failure of all three severe functions',
        Category: 'Voice/speech impairment',
    },
    {
        value:
            'Voice/speech impairment - Total and permanent loss of the ability to speak as confirmed by an appropriate specialist',
        label:
            'Voice/speech impairment - Total and permanent loss of the ability to speak as confirmed by an appropriate specialist',
        Category: 'Voice/speech impairment',
    },
    {
        value:
            'White blood cells - Marked physical weakness despite treatment; and - Leukaemia requiring chemotherapy 3 to 6 times in the last 12 months; and - Granulocyte count consistently below 0.5 x 10*9/L with at least two documented community acquired or opportunistic infections',
        label:
            'White blood cells - Marked physical weakness despite treatment; and - Leukaemia requiring chemotherapy 3 to 6 times in the last 12 months; and - Granulocyte count consistently below 0.5 x 10*9/L with at least two documented community acquired or opportunistic infections',
        Category: 'White blood cells',
    },
    {
        value:
            'White blood cells - Marked physical weakness despite treatment; and - Leukaemia requiring chemotherapy 3 to 6 times in the last 12 months; and - Granulocyte count consistently below 0.5 x 10*9/L, with more than one life-threatening infection requiring hospitalisation in the last 12 months.',
        label:
            'White blood cells - Marked physical weakness despite treatment; and - Leukaemia requiring chemotherapy 3 to 6 times in the last 12 months; and - Granulocyte count consistently below 0.5 x 10*9/L, with more than one life-threatening infection requiring hospitalisation in the last 12 months.',
        Category: 'White blood cells',
    },
    {
        value:
            'White blood cells - Terminal condition causing severe impairment; and - Leukaemia requiring chemotherapy more than 6 times in the last 12 months; and - Granulocyte count consistently below 0.5 x 10*9/L, with more than one life-threatening infection requiring hospitalisation in the last 12 months.',
        label:
            'White blood cells - Terminal condition causing severe impairment; and - Leukaemia requiring chemotherapy more than 6 times in the last 12 months; and - Granulocyte count consistently below 0.5 x 10*9/L, with more than one life-threatening infection requiring hospitalisation in the last 12 months.',
        Category: 'White blood cells',
    },
    {
        value: 'Other',
        label: 'Other',
        Category: 'Other',
    },
];
