export * from './claim-condition-dread';
export const ClaimConditions = [
    {
        value: 'Accidental HIV - Proof of accidental contraction HIV as per policy document',
        label: 'Accidental HIV - Proof of accidental contraction HIV as per policy document',
        Category: 'Accidental HIV',
    },
    { value: 'AIDS', label: 'AIDS', Category: 'HIV/AIDS' },
    {
        value: `Alzheimer's disease and progressive dementia`,
        label: `Alzheimer's disease and progressive dementia`,
        Category: 'Organic brain disorders and dementia',
    },
    {
        value:
            'Activities of Daily Living catch-all - Permanent inability to perform 1 or more Basic ADLs, and permanent inability to perform 2 or more Instrumental ADLs',
        label:
            'Activities of Daily Living catch-all - Permanent inability to perform 1 or more Basic ADLs, and permanent inability to perform 2 or more Instrumental ADLs',
        Category: 'Activities of daily living',
    },
    {
        value:
            'Activities of Daily Living catch-all - Permanent inability to perform 3 or more Basic ADLs, and permanent inability to perform 4 or more Instrumental ADLs',
        label:
            'Activities of Daily Living catch-all - Permanent inability to perform 3 or more Basic ADLs, and permanent inability to perform 4 or more Instrumental ADLs',
        Category: 'Activities of daily living',
    },
    {
        value:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 2 weeks.',
        label:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 2 weeks.',
        Category: 'Anaemia',
    },
    {
        value:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 6 weeks',
        label:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 6 weeks',
        Category: 'Anaemia',
    },
    {
        value:
            'Anorectal disease - Complete and permanent faecal incontinence not amenable to colostomy',
        label:
            'Anorectal disease - Complete and permanent faecal incontinence not amenable to colostomy',
        Category: 'Anorectal disease',
    },
    {
        value:
            'Anorectal disease - Complete and permanent faecal incontinence treated with permanent colostomy',
        label:
            'Anorectal disease - Complete and permanent faecal incontinence treated with permanent colostomy',
        Category: 'Anorectal disease',
    },
    {
        value: 'Anorectal disease - Partial but permanent faecal incontinence',
        label: 'Anorectal disease - Partial but permanent faecal incontinence',
        Category: 'Anorectal disease',
    },
    {
        value: 'Aphasia/dysphasia - Receptive aphasia or dysphasia',
        label: 'Aphasia/dysphasia - Receptive aphasia or dysphasia',
        Category: 'Aphasia/dysphasia',
    },
    {
        value:
            'Arrhythmias - Symptoms are present continually and documented on Holter ECG and NYHA IV',
        label:
            'Arrhythmias - Symptoms are present continually and documented on Holter ECG and NYHA IV',
        Category: 'Arrhythmias',
    },
    {
        value:
            'Arrhythmias - Symptoms are present frequently and documented on Holter ECG and NYHA III',
        label:
            'Arrhythmias - Symptoms are present frequently and documented on Holter ECG and NYHA III',
        Category: 'Arrhythmias',
    },
    {
        value: 'Biliary tract - Irreparable biliary tract obstruction',
        label: 'Biliary tract - Irreparable biliary tract obstruction',
        Category: 'Biliary tract',
    },
    {
        value: 'Bladder - Surgical removal of the bladder',
        label: 'Bladder - Surgical removal of the bladder',
        Category: 'Bladder',
    },
    {
        value:
            'Bladder - Total urinary incontinence, loss of reflex or voluntary urine control despite medical and surgical treatment',
        label:
            'Bladder - Total urinary incontinence, loss of reflex or voluntary urine control despite medical and surgical treatment',
        Category: 'Bladder',
    },
    {
        value: 'BCC/SCC',
        label: 'BCC/SCC',
        Category: 'BCC/SCC',
    },
    {
        value:
            'Cancer - Cancer which has resulted in documented permanent end-organ dysfunction or damage - assessed based on the affected end-organ system.',
        label:
            'Cancer - Cancer which has resulted in documented permanent end-organ dysfunction or damage - assessed based on the affected end-organ system.',
        Category: 'Cancer',
    },
    {
        value: 'Cancer and leukaemia - Blood/bone marrow cancer',
        label: 'Cancer and leukaemia - Blood/bone marrow cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Brain cancer',
        label: 'Cancer and leukaemia - Brain cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Breast cancer',
        label: 'Cancer and leukaemia - Breast cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Diagnosis of acute myoblastic leukaemia with poor prognosis',
        label: 'Cancer and leukaemia - Diagnosis of acute myoblastic leukaemia with poor prognosis',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Failure of leukaemia treatment',
        label: 'Cancer and leukaemia - Failure of leukaemia treatment',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Lung cancer',
        label: 'Cancer and leukaemia - Lung cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Lymph cancer',
        label: 'Cancer and leukaemia - Lymph cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Metastatic cancer',
        label: 'Cancer and leukaemia - Metastatic cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Prostate cancer',
        label: 'Cancer and leukaemia - Prostate cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Relapse of leukaemia',
        label: 'Cancer and leukaemia - Relapse of leukaemia',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Requiring a bone marrow transplant for leukaemia',
        label: 'Cancer and leukaemia - Requiring a bone marrow transplant for leukaemia',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Skin cancer',
        label: 'Cancer and leukaemia - Skin cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cardiomyopathy - EF 35-40%',
        label: 'Cardiomyopathy - EF 35-40%',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - EF less than 35%',
        label: 'Cardiomyopathy - EF less than 35%',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - EF less than 45%',
        label: 'Cardiomyopathy - EF less than 45%',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - METS 2-5',
        label: 'Cardiomyopathy - METS 2-5',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - METS 5 or less',
        label: 'Cardiomyopathy - METS 5 or less',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - METS less than 2',
        label: 'Cardiomyopathy - METS less than 2',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - NYHA III',
        label: 'Cardiomyopathy - NYHA III',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - NYHA III or greater',
        label: 'Cardiomyopathy - NYHA III or greater',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - NYHA IV',
        label: 'Cardiomyopathy - NYHA IV',
        Category: 'Cardiomyopathy',
    },
    {
        value: 'Cardiomyopathy - Recovered from surgery for hypertrophic cardiomyopathy',
        label: 'Cardiomyopathy - Recovered from surgery for hypertrophic cardiomyopathy',
        Category: 'Cardiomyopathy',
    },
    {
        value:
            'Cardiovascular system - Receiving optimal treatment, but still symptomatic NYHA class 4, with METS < 3 or EF < 40%',
        label:
            'Cardiovascular system - Receiving optimal treatment, but still symptomatic NYHA class 4, with METS < 3 or EF < 40%',
        Category: 'Cardiovascular system',
    },
    {
        value: 'Carcinoid',
        label: 'Carcinoid',
        Category: 'Carcinoid',
    },
    {
        value:
            'Clotting disorders - Bleeds or clots which have resulted in documented permanent end-organ dysfunction or damage in spite of surgical or medical treatment',
        label:
            'Clotting disorders - Bleeds or clots which have resulted in documented permanent end-organ dysfunction or damage in spite of surgical or medical treatment',
        Category: 'Clotting disorders',
    },
    {
        value: 'Coronary artery disease - EF 35-40%',
        label: 'Coronary artery disease - EF 35-40%',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - EF less than 35%',
        label: 'Coronary artery disease - EF less than 35%',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - EF less than 45%',
        label: 'Coronary artery disease - EF less than 45%',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - METS 2-5',
        label: 'Coronary artery disease - METS 2-5',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - METS 5 or less',
        label: 'Coronary artery disease - METS 5 or less',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - METS less than 2',
        label: 'Coronary artery disease - METS less than 2',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - NYHA III',
        label: 'Coronary artery disease - NYHA III',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - NYHA III or greater',
        label: 'Coronary artery disease - NYHA III or greater',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - NYHA IV',
        label: 'Coronary artery disease - NYHA IV',
        Category: 'Coronary artery disease',
    },
    {
        value:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Inability to swallow, requiring regular suctioning to prevent aspiration or choking',
        label:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Inability to swallow, requiring regular suctioning to prevent aspiration or choking',
        Category: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
    },
    {
        value:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Moderate to severe dysarthria or dysphasia, nasal regurgitation and aspiration',
        label:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Moderate to severe dysarthria or dysphasia, nasal regurgitation and aspiration',
        Category: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
    },
    {
        value:
            'Cranial nerve V (trigeminal nerve) - Severe uncontrolled unilateral or bilateral facial neuralgic pain affecting either speech or eating',
        label:
            'Cranial nerve V (trigeminal nerve) - Severe uncontrolled unilateral or bilateral facial neuralgic pain affecting either speech or eating',
        Category: 'Cranial nerve V (trigeminal nerve)',
    },
    {
        value:
            'Cranial nerve VII (facial nerve) - Unilateral or bilateral facial nerve paralysis with the inability to control eyelid closure',
        label:
            'Cranial nerve VII (facial nerve) - Unilateral or bilateral facial nerve paralysis with the inability to control eyelid closure',
        Category: 'Cranial nerve VII (facial nerve)',
    },
    {
        value:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and presence of symptoms  leading to the inability to climb stairs or walk unaided by another person, with abnormal findings on electronystagmography or videonystagmography.     ',
        label:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and presence of symptoms  leading to the inability to climb stairs or walk unaided by another person, with abnormal findings on electronystagmography or videonystagmography.     ',
        Category: 'Cranial nerve VIII (vestibulocochlear nerve)',
    },
    {
        value:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and the presence of listed symptoms',
        label:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and the presence of listed symptoms',
        Category: 'Cranial nerve VIII (vestibulocochlear nerve)',
    },
    {
        value: 'Diplegia / paraplegia - Permanent diplegia / paraplegia',
        label: 'Diplegia / paraplegia - Permanent diplegia / paraplegia',
        Category: 'Diplegia / paraplegia',
    },
    {
        value: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA III',
        label: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA III',
        Category: 'Diseases of the aorta',
    },
    {
        value: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA IV',
        label: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA IV',
        Category: 'Diseases of the aorta',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with NYHA III',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with NYHA III',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with right heart failure',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with NYHA IV',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with NYHA IV',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with right heart failure',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with NYHA III',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with NYHA III',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with right heart failure',
        Category: 'Diseases of the pulmonary arteries',
    },
    {
        value: 'Episodic loss of consciousness - Seizures',
        label: 'Episodic loss of consciousness - Seizures',
        Category: 'Episodic loss of consciousness',
    },
    {
        value: 'Episodic loss of consciousness - Status epilepticus',
        label: 'Episodic loss of consciousness - Status epilepticus',
        Category: 'Episodic loss of consciousness',
    },
    { value: 'Face - Disfigurement', label: 'Face - Disfigurement', Category: 'Face' },
    {
        value:
            'Gait disorders/motor coordination - Difficulty with grasping, pincer grip, fine or gross motor coordination or grip strength',
        label:
            'Gait disorders/motor coordination - Difficulty with grasping, pincer grip, fine or gross motor coordination or grip strength',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value:
            'Gait disorders/motor coordination - Difficulty with standing, maintaining a standing position and needing assistance with walking',
        label:
            'Gait disorders/motor coordination - Difficulty with standing, maintaining a standing position and needing assistance with walking',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions and documented previous falls and inability to stand, walk, stoop, squat, kneel, climb stairs',
        label:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions and documented previous falls and inability to stand, walk, stoop, squat, kneel, climb stairs',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions; and inability to grasp, pincer grip and inability with fine or gross motor coordination or grip strength',
        label:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions; and inability to grasp, pincer grip and inability with fine or gross motor coordination or grip strength',
        Category: 'Gait disorders/motor coordination',
    },
    {
        value: 'Hearing - Total and irreversible average hearing loss',
        label: 'Hearing - Total and irreversible average hearing loss',
        Category: 'Hearing',
    },
    {
        value: 'Heart transplant - Heart transplant',
        label: 'Heart transplant - Heart transplant',
        Category: 'Heart transplant',
    },
    {
        value: 'Hemiplegia - Permanent hemiplegia',
        label: 'Hemiplegia - Permanent hemiplegia',
        Category: 'Hemiplegia',
    },
    {
        value:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications (strangulations, obstructions, ischaemia and gangrene)',
        label:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications (strangulations, obstructions, ischaemia and gangrene)',
        Category: 'Hernia',
    },
    {
        value:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications, as specified in the policy document, and; weight loss resulting in a BMI of less than 14.5.',
        label:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications, as specified in the policy document, and; weight loss resulting in a BMI of less than 14.5.',
        Category: 'Hernia',
    },
    {
        value: 'Hypertensive cardiovascular disease - Documented Stage 3 hypertension',
        label: 'Hypertensive cardiovascular disease - Documented Stage 3 hypertension',
        Category: 'Hypertensive cardiovascular disease',
    },
    {
        value: 'Hypertensive cardiovascular disease - Renal system impairment',
        label: 'Hypertensive cardiovascular disease - Renal system impairment',
        Category: 'Hypertensive cardiovascular disease',
    },
    {
        value: 'Impairment of consciousness - Coma',
        label: 'Impairment of consciousness - Coma',
        Category: 'Impairment of consciousness',
    },
    {
        value: 'Liver disease - Abnormal liver function tests',
        label: 'Liver disease - Abnormal liver function tests',
        Category: 'Liver disease',
    },
    {
        value:
            'Liver disease - Persistent jaundice and bleeding varies, hepatoxicity and severe symptoms and signs uncontrolled by medical or surgical treatment',
        label:
            'Liver disease - Persistent jaundice and bleeding varies, hepatoxicity and severe symptoms and signs uncontrolled by medical or surgical treatment',
        Category: 'Liver disease',
    },
    {
        value:
            'Lower gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment; and weight loss resulting in a BMI of less than 16 due to disease.',
        label:
            'Lower gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment; and weight loss resulting in a BMI of less than 16 due to disease.',
        Category: 'Lower gastrointestinal tract',
    },
    {
        value:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        label:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        Category: 'Lower gastrointestinal tract',
    },
    {
        value:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        label:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        Category: 'Lower gastrointestinal tract',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Bilateral involvement requiring use of assistive devices for hand function',
        label:
            'Lumbar, thoracic and cervical spine - Bilateral involvement requiring use of assistive devices for hand function',
        Category: 'Lumbar, thoracic and cervical spine',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: cauda equine syndrome',
        label:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: cauda equine syndrome',
        Category: 'Lumbar, thoracic and cervical spine',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: unilateral involvement requiring use of assistive devices for hand function',
        label:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: unilateral involvement requiring use of assistive devices for hand function',
        Category: 'Lumbar, thoracic and cervical spine',
    },
    {
        value: 'Malignant melanoma',
        label: 'Malignant melanoma',
        Category: 'Cancer',
    },
    {
        value: 'Multiple myeloma',
        label: 'Multiple myeloma',
        Category: 'Cancer',
    },
    {
        value:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed',
        label:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed',
        Category: 'Neurological impairment of respiration',
    },
    {
        value:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed but not requiring ventilator assistance',
        label:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed but not requiring ventilator assistance',
        Category: 'Neurological impairment of respiration',
    },
    {
        value:
            'Neurological impairment of respiration - No capacity for spontaneous respiration and requiring ventilation',
        label:
            'Neurological impairment of respiration - No capacity for spontaneous respiration and requiring ventilation',
        Category: 'Neurological impairment of respiration',
    },
    {
        value:
            'Organic brain disorders and dementia - Organic brain disorders and clinical dementia',
        label:
            'Organic brain disorders and dementia - Organic brain disorders and clinical dementia',
        Category: 'Organic brain disorders and dementia',
    },
    { value: 'Paralysis - Hemiplegia', label: 'Paralysis - Hemiplegia', Category: 'Paralysis' },
    { value: 'Paralysis - Paraplegia', label: 'Paralysis - Paraplegia', Category: 'Paralysis' },
    { value: 'Paralysis - Quadriplegia', label: 'Paralysis - Quadriplegia', Category: 'Paralysis' },
    {
        value: 'Pericardial disease - EF 35-40%',
        label: 'Pericardial disease - EF 35-40%',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - EF less than 35%',
        label: 'Pericardial disease - EF less than 35%',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - EF less than 45%',
        label: 'Pericardial disease - EF less than 45%',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - METS 2-5',
        label: 'Pericardial disease - METS 2-5',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - METS 5 or less',
        label: 'Pericardial disease - METS 5 or less',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - METS less than 2',
        label: 'Pericardial disease - METS less than 2',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - NYHA III',
        label: 'Pericardial disease - NYHA III',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - NYHA III or greater',
        label: 'Pericardial disease - NYHA III or greater',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - NYHA IV',
        label: 'Pericardial disease - NYHA IV',
        Category: 'Pericardial disease',
    },
    {
        value:
            'Peripheral arterial disease - Abnormal diminished pulse on Doppler Readings and Cold leg; and Pain on exercise',
        label:
            'Peripheral arterial disease - Abnormal diminished pulse on Doppler Readings and Cold leg; and Pain on exercise',
        Category: 'Peripheral arterial disease',
    },
    {
        value: 'Peripheral arterial disease - Gangrene',
        label: 'Peripheral arterial disease - Gangrene',
        Category: 'Peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - Persistent ischaemia with no detectable pulse on Doppler readings',
        label:
            'Peripheral arterial disease - Persistent ischaemia with no detectable pulse on Doppler readings',
        Category: 'Peripheral arterial disease',
    },
    {
        value: 'Peripheral arterial disease - Severe vascular ulceration',
        label: 'Peripheral arterial disease - Severe vascular ulceration',
        Category: 'Peripheral arterial disease',
    },
    {
        value: 'Peripheral venous disease - Severe, deep and widespread vascular ulceration',
        label: 'Peripheral venous disease - Severe, deep and widespread vascular ulceration',
        Category: 'Peripheral venous disease',
    },
    {
        value: 'Quadriplegia - Permanent quadriplegia',
        label: 'Quadriplegia - Permanent quadriplegia',
        Category: 'Quadriplegia',
    },
    {
        value:
            'Schizophrenia/other pyschotic disorders - Institutionalised at least 3 times in the last 12 months with each admission lasting for longer than 6 weeks; and GAF less than 40.',
        label:
            'Schizophrenia/other pyschotic disorders - Institutionalised at least 3 times in the last 12 months with each admission lasting for longer than 6 weeks; and GAF less than 40.',
        Category: 'Schizophrenia/other pyschotic disorders',
    },
    {
        value:
            'Schizophrenia/other pyschotic disorders - Requiring permanent institutionalisation or permanent caregiver; and GAF less than 30',
        label:
            'Schizophrenia/other pyschotic disorders - Requiring permanent institutionalisation or permanent caregiver; and GAF less than 30',
        Category: 'Schizophrenia/other pyschotic disorders',
    },
    {
        value: 'Sleep disorders - Essential narcolepsy',
        label: 'Sleep disorders - Essential narcolepsy',
        Category: 'Sleep disorders',
    },
    {
        value:
            'Stroke (cerebrovascular incident) - Either four of the following: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, Moderate cognitive problems, or Homonymous Hemianopia',
        label:
            'Stroke (cerebrovascular incident) - Either four of the following: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, Moderate cognitive problems, or Homonymous Hemianopia',
        Category: 'Stroke (cerebrovascular incident)',
    },
    {
        value:
            'Stroke (cerebrovascular incident) - The inability to perform any one of the following categories: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, or Moderate cognitive problems',
        label:
            'Stroke (cerebrovascular incident) - The inability to perform any one of the following categories: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, or Moderate cognitive problems',
        Category: 'Stroke (cerebrovascular incident)',
    },
    {
        value:
            'The endocrine system - Symptoms and signs of abnormality of the endocrine system leading to targer end-organ damage despite appropriate surgical and medical treatment including replacement or blockades',
        label:
            'The endocrine system - Symptoms and signs of abnormality of the endocrine system leading to targer end-organ damage despite appropriate surgical and medical treatment including replacement or blockades',
        Category: 'The endocrine system',
    },
    {
        value:
            'The limbs - At least 2 of the following: Hand at MP joint, Arm below the elbow, Arm above the elbow, Leg through the hip, Leg above the knee, Leg below the knee',
        label:
            'The limbs - At least 2 of the following: Hand at MP joint, Arm below the elbow, Arm above the elbow, Leg through the hip, Leg above the knee, Leg below the knee',
        Category: 'The limbs',
    },
    { value: 'The limbs - Hemipelvectomy', label: 'The limbs - Hemipelvectomy', Category: 'Test' },
    {
        value: 'The limbs - Loss of or loss of use of arm below the elbow',
        label: 'The limbs - Loss of or loss of use of arm below the elbow',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of arm below the shoulder',
        label: 'The limbs - Loss of or loss of use of arm below the shoulder',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of foot',
        label: 'The limbs - Loss of or loss of use of foot',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of hand at MP joint',
        label: 'The limbs - Loss of or loss of use of hand at MP joint',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg above the kness',
        label: 'The limbs - Loss of or loss of use of leg above the kness',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg below the knee',
        label: 'The limbs - Loss of or loss of use of leg below the knee',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg through the hip',
        label: 'The limbs - Loss of or loss of use of leg through the hip',
        Category: 'The limbs',
    },
    {
        value: 'The limbs - Loss of or loss of use of thumb through MP joint',
        label: 'The limbs - Loss of or loss of use of thumb through MP joint',
        Category: 'The limbs',
    },
    {
        value: 'The respiratory system - DCO less than 40% of predicted',
        label: 'The respiratory system - DCO less than 40% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - DCO less than 60% of predicted',
        label: 'The respiratory system - DCO less than 60% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - DCO of 40 - 59% of predicted',
        label: 'The respiratory system - DCO of 40 - 59% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FEV1 less than 40% of predicted',
        label: 'The respiratory system - FEV1 less than 40% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FEV1 less than 60% of predicted',
        label: 'The respiratory system - FEV1 less than 60% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FEV1 of 40 - 59% of predicted',
        label: 'The respiratory system - FEV1 of 40 - 59% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC less than 40% of predicted',
        label: 'The respiratory system - FVC less than 40% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC less than 60% of predicted',
        label: 'The respiratory system - FVC less than 60% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC of 40 - 49% of predicted',
        label: 'The respiratory system - FVC of 40 - 49% of predicted',
        Category: 'The respiratory system',
    },
    {
        value: 'The respiratory system - FVC of 50 - 59% of predicted',
        label: 'The respiratory system - FVC of 50 - 59% of predicted',
        Category: 'The respiratory system',
    },
    {
        value:
            'The skin - Extensive permanent involvement of an upper and lower limb including the sole of one foot and the palm of one hand',
        label:
            'The skin - Extensive permanent involvement of an upper and lower limb including the sole of one foot and the palm of one hand',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral lower limbs including the soles of both feet',
        label:
            'The skin - Extensive permanent involvement of the bilateral lower limbs including the soles of both feet',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands',
        label:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands; and restriction of joint mobility',
        label:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands; and restriction of joint mobility',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Third degree burns involving more than 20% of the total body surface area',
        label:
            'The skin - Third degree burns involving more than 20% of the total body surface area',
        Category: 'The skin',
    },
    {
        value:
            'The skin - Third degree burns involving more than 30% of the total body surface area',
        label:
            'The skin - Third degree burns involving more than 30% of the total body surface area',
        Category: 'The skin',
    },
    {
        value: 'The visual system - Diabetic retinopathy',
        label: 'The visual system - Diabetic retinopathy',
        Category: 'The visual system',
    },
    {
        value: 'The visual system - Hemianopia',
        label: 'The visual system - Hemianopia',
        Category: 'The visual system',
    },
    {
        value: 'The visual system - Irreversible visual acuity impairment',
        label: 'The visual system - Irreversible visual acuity impairment',
        Category: 'The visual system',
    },
    {
        value: 'Upper air passages - Dyspnoea',
        label: 'Upper air passages - Dyspnoea',
        Category: 'Upper air passages',
    },
    {
        value:
            'Upper gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment and weight loss resulting in a BMI of less than 16 due to disease.',
        label:
            'Upper gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment and weight loss resulting in a BMI of less than 16 due to disease.',
        Category: 'Upper gastrointestinal tract',
    },
    {
        value:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        label:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        Category: 'Upper gastrointestinal tract',
    },
    {
        value:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        label:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        Category: 'Upper gastrointestinal tract',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR 24-40ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR 24-40ml/min despite treatment',
        Category: 'Upper urinary tract',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 24ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 24ml/min despite treatment',
        Category: 'Upper urinary tract',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 40ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 40ml/min despite treatment',
        Category: 'Upper urinary tract',
    },
    {
        value: 'Upper urinary tract - Requires ongoing dialysis',
        label: 'Upper urinary tract - Requires ongoing dialysis',
        Category: 'Upper urinary tract',
    },
    {
        value:
            'Urethra - Urethral dysfunction and loss of voluntary urine control; and requiring an indwelling catheter.',
        label:
            'Urethra - Urethral dysfunction and loss of voluntary urine control; and requiring an indwelling catheter.',
        Category: 'Urethra',
    },
    {
        value: 'Valvular heart disease - EF 35-40%',
        label: 'Valvular heart disease - EF 35-40%',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - EF less than 35%',
        label: 'Valvular heart disease - EF less than 35%',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - EF less than 45%',
        label: 'Valvular heart disease - EF less than 45%',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - METS 2-5',
        label: 'Valvular heart disease - METS 2-5',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - METS 5 or less',
        label: 'Valvular heart disease - METS 5 or less',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - METS less than 2',
        label: 'Valvular heart disease - METS less than 2',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - NYHA III',
        label: 'Valvular heart disease - NYHA III',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - NYHA III or greater',
        label: 'Valvular heart disease - NYHA III or greater',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Valvular heart disease - NYHA IV',
        label: 'Valvular heart disease - NYHA IV',
        Category: 'Valvular heart disease',
    },
    {
        value: 'Vestibular disorders - Vestibular dyequilibrium',
        label: 'Vestibular disorders - Vestibular dyequilibrium',
        Category: 'Vestibular disorders',
    },
    {
        value: 'Voice/speech impairment - Failure of all three functions',
        label: 'Voice/speech impairment - Failure of all three functions',
        Category: 'Voice/speech impairment',
    },
    {
        value: 'Voice/speech impairment - Failure of all three moderate functions',
        label: 'Voice/speech impairment - Failure of all three moderate functions',
        Category: 'Voice/speech impairment',
    },
    {
        value: 'Voice/speech impairment - Failure of all three severe functions',
        label: 'Voice/speech impairment - Failure of all three severe functions',
        Category: 'Voice/speech impairment',
    },
    {
        value:
            'Voice/speech impairment - Total and permanent loss of the ability to speak as confirmed by an appropriate specialist',
        label:
            'Voice/speech impairment - Total and permanent loss of the ability to speak as confirmed by an appropriate specialist',
        Category: 'Voice/speech impairment',
    },
    {
        value: 'White blood cells - Leukaemia, lymphoma and white cell abnormalities',
        label: 'White blood cells - Leukaemia, lymphoma and white cell abnormalities',
        Category: 'White blood cells',
    },
    { value: 'Other', label: 'Other', Category: 'Other' },
];
