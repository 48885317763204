export const BenefitCategory = [
    { value: 'Anaemia', label: 'Anaemia', Category: 'Blood disorders' },
    {
        value: 'Anorectal disease',
        label: 'Anorectal disease',
        Category: 'Gastrointestinal disorders',
    },
    {
        value: 'Aphasia/dysphasia',
        label: 'Aphasia/dysphasia',
        Category: 'Central nervous system disorders',
    },
    { value: 'Arrhythmias', label: 'Arrhythmias', Category: 'Cardiovascular disorders' },
    { value: 'Biliary tract', label: 'Biliary tract', Category: 'Gastrointestinal disorders' },
    { value: 'Bladder', label: 'Bladder', Category: 'Genitourinary disorders' },
    { value: 'Bipolar disorder', label: 'Bipolar disorder', Category: 'Mental disorders' },
    { value: 'Bereavement', label: 'Bereavement', Category: 'Mental disorders' },
    { value: 'Bones–other', label: 'Bones–other', Category: 'Musculo-skeletal disorders' },
    {
        value: 'Bones–upper limb',
        label: 'Bones–upper limb',
        Category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Bones–lower limb',
        label: 'Bones–lower limb',
        Category: 'Musculo-skeletal disorders',
    },
    { value: 'Cancer', label: 'Cancer', Category: 'Cancer' },
    { value: 'Cancer and leukaemia', label: 'Cancer and leukaemia', Category: 'Cancer' },
    { value: 'Cardiomyopathy', label: 'Cardiomyopathy', Category: 'Cancer' },
    {
        value: 'Cardiovascular system',
        label: 'Cardiovascular system',
        Category: 'Cardiovascular disorders',
    },
    { value: 'Clotting disorders', label: 'Clotting disorders', Category: 'Blood disorders' },
    {
        value: 'Coronary artery disease',
        label: 'Coronary artery disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
        label: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Cranial nerve V (trigeminal nerve)',
        label: 'Cranial nerve V (trigeminal nerve)',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Cranial nerve VII (facial nerve)',
        label: 'Cranial nerve VII (facial nerve)',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Cranial nerve VIII (vestibulocochlear nerve)',
        label: 'Cranial nerve VIII (vestibulocochlear nerve)',
        Category: 'Central nervous system disorders',
    },
    { value: 'Depression/anxiety', label: 'Depression/anxiety', Category: 'Mental disorders' },
    {
        value: 'Diplegia / paraplegia',
        label: 'Diplegia / paraplegia',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Diseases of the aorta',
        label: 'Diseases of the aorta',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Diseases of the pulmonary arteries',
        label: 'Diseases of the pulmonary arteries',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Episodic loss of consciousness',
        label: 'Episodic loss of consciousness',
        Category: 'Central nervous system disorders',
    },
    { value: 'Face', label: 'Face', Category: 'Trauma' },
    {
        value: 'Gait disorders/motor coordination',
        label: 'Gait disorders/motor coordination',
        Category: 'Musculo-skeletal disorders',
    },
    { value: 'Hearing', label: 'Hearing', Category: 'Ear, nose and throat disorders' },
    { value: 'Heart transplant', label: 'Heart transplant', Category: 'Cardiovascular disorders' },
    { value: 'Hemiplegia', label: 'Hemiplegia', Category: 'Central nervous system disorders' },
    { value: 'Hernia', label: 'Hernia', Category: 'Gastrointestinal disorders' },
    {
        value: 'Hypertensive cardiovascular disease',
        label: 'Hypertensive cardiovascular disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Impairment of consciousness',
        label: 'Impairment of consciousness',
        Category: 'Central nervous system disorders',
    },
    { value: 'Inability to work', label: 'Inability to work', Category: 'Other' },
    { value: 'Liver disease', label: 'Liver disease', Category: 'Gastrointestinal disorders' },
    {
        value: 'Joints–upper limb',
        label: 'Joints–upper limb',
        Category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Joints–lower limb',
        label: 'Joints–lower limb',
        Category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Lower gastrointestinal tract',
        label: 'Lower gastrointestinal tract',
        Category: 'Gastrointestinal disorders',
    },
    {
        value: 'Lumbar, thoracic and cervical spine',
        label: 'Lumbar, thoracic and cervical spine',
        Category: 'Musculo-skeletal disorders',
    },
    { value: 'Muscles', label: 'Muscles', Category: 'Musculo-skeletal disorders' },
    {
        value: 'Neurological impairment of respiration',
        label: 'Neurological impairment of respiration',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Organic brain disorders and dementia',
        label: 'Organic brain disorders and dementia',
        Category: 'Central nervous system disorders',
    },
    { value: 'Paralysis', label: 'Paralysis', Category: 'Central nervous system disorders' },
    {
        value: 'Pericardial disease',
        label: 'Pericardial disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Peripheral arterial disease',
        label: 'Peripheral arterial disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Peripheral venous disease',
        label: 'Peripheral venous disease',
        Category: 'Cardiovascular disorders',
    },
    { value: 'PTSD', label: 'PTSD', Category: 'Mental disorders' },
    { value: 'Quadriplegia', label: 'Quadriplegia', Category: 'Central nervous system disorders' },
    {
        value: 'Schizophrenia/other pyschotic disorders',
        label: 'Schizophrenia/other pyschotic disorders',
        Category: 'Mental disorders',
    },
    {
        value: 'Sleep disorders',
        label: 'Sleep disorders',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Stroke (cerebrovascular incident)',
        label: 'Stroke (cerebrovascular incident)',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'The endocrine system',
        label: 'The endocrine system',
        Category: 'Endocrine disorders',
    },
    { value: 'The limbs', label: 'The limbs', Category: 'Musculo-skeletal disorders' },
    {
        value: 'The respiratory system',
        label: 'The respiratory system',
        Category: 'Respiratory disorders',
    },
    { value: 'The skin', label: 'The skin', Category: 'Skin disorders' },
    { value: 'The visual system', label: 'The visual system', Category: 'The visual system' },
    { value: 'Upper air passages', label: 'Upper air passages', Category: 'Respiratory disorders' },
    {
        value: 'Upper gastrointestinal tract',
        label: 'Upper gastrointestinal tract',
        Category: 'Gastrointestinal disorders',
    },
    { value: 'Upper urinary tract', label: 'Upper urinary tract', Category: 'Renal disorders' },
    { value: 'Urethra', label: 'Urethra', Category: 'Genitourinary disorders' },
    {
        value: 'Valvular heart disease',
        label: 'Valvular heart disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Vestibular disorders',
        label: 'Vestibular disorders',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Voice/speech impairment',
        label: 'Voice/speech impairment',
        Category: 'Central nervous system disorders',
    },
    { value: 'White blood cells', label: 'White blood cells', Category: 'Blood disorders' },
    { value: 'Other', label: 'Other', Category: 'Other' },
];

export const BenefitCategoryDread = [
    { value: 'Accidental HIV', label: 'Accidental HIV', Category: 'Accidental' },
    { value: 'AIDS', label: 'AIDS', Category: 'HIV/AIDS' },
    {
        value: `Alzheimer's disease and progressive dementia`,
        label: `Alzheimer's disease and progressive dementia`,
        Category: 'Central nervous system disorders',
    },
    { value: 'Aneurysm', label: 'Aneurysm', Category: 'Cardiovascular disorders' },
    {
        value: 'Aplastic anaemia and other blood disorders',
        label: 'Aplastic anaemia and other blood disorders',
        Category: 'Blood disorders',
    },
    { value: 'Arrhythmia', label: 'Arrhythmia', Category: 'Cardiovascular disorders' },
    { value: 'Bipolar disorder', label: 'Bipolar disorder', Category: 'Mental disorders' },
    { value: 'Bereavement', label: 'Bereavement', Category: 'Mental disorders' },
    { value: 'Bones–other ', label: 'Bones–other', Category: 'Musculo-skeletal disorders' },
    {
        value: 'Bones–upper limb',
        label: 'Bones–upper limb',
        Category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Bones–lower limb',
        label: 'Bones–lower limb',
        Category: 'Musculo-skeletal disorders',
    },
    { value: 'Cancer and leukaemia', label: 'Cancer and leukaemia', Category: 'Cancer' },
    { value: 'Cardiomyopathy', label: 'Cardiomyopathy', Category: 'Cancer' },
    { value: 'BCC/SCC', label: 'BCC/SCC', Category: 'Cancer' },
    {
        value: 'Cardio-pulmonary dysfunction',
        label: 'Cardio-pulmonary dysfunction',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Carotid arterial disease',
        label: 'Carotid arterial disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Cerebrovascular incident (Stoke)',
        label: 'Cerebrovascular incident (Stoke)',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Chronic liver disorders',
        label: 'Chronic liver disorders',
        Category: 'Gastrointestinal disorders',
    },
    { value: 'Coma', label: 'Coma', Category: 'Central nervous system disorders' },
    {
        value: 'Coronary artery disease',
        label: 'Coronary artery disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
        label: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Cranial nerve V (trigeminal nerve)',
        label: 'Cranial nerve V (trigeminal nerve)',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Cranial nerve VII (facial nerve)',
        label: 'Cranial nerve VII (facial nerve)',
        Category: 'Central nervous system disorders',
    },
    { value: 'Depression/anxiety', label: 'Depression/anxiety', Category: 'Mental disorders' },
    {
        value: 'Ear, nose and throat disorders',
        label: 'Ear, nose and throat disorders',
        Category: 'Ear, nose and throat disorders',
    },
    {
        value: 'Endocrine conditions',
        label: 'Endocrine conditions',
        Category: 'Endocrine disorders',
    },
    { value: 'Eye disorders', label: 'Eye disorders', Category: 'The visual system' },
    {
        value: 'Gastrointestinal disease',
        label: 'Gastrointestinal disease',
        Category: 'Gastrointestinal disorders',
    },
    { value: 'Heart transplant', label: 'Heart transplant', Category: 'Cardiovascular disorders' },
    { value: 'Hernia', label: 'Hernia', Category: 'Gastrointestinal disorders' },
    {
        value: 'Hypertensive cardiovascular disease',
        label: 'Hypertensive cardiovascular disease',
        Category: 'Cardiovascular disorders',
    },
    { value: 'Living Lifestyle catch-all', label: 'Living Lifestyle catch-all', Category: 'Other' },
    {
        value: 'Loss of and loss of use of limbs',
        label: 'Loss of and loss of use of limbs',
        Category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Lumbar, thoracic and cervical spine',
        label: 'Lumbar, thoracic and cervical spine',
        Category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Joints–upper limb',
        label: 'Joints–upper limb',
        Category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Joints–lower limb',
        label: 'Joints–lower limb',
        Category: 'Musculo-skeletal disorders',
    },
    { value: 'Major burns', label: 'Major burns', Category: 'Major burns' },
    {
        value: 'Motor neuron disease',
        label: 'Motor neuron disease',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Multiple sclerosis',
        label: 'Multiple sclerosis',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Muscular dystrophy',
        label: 'Muscular dystrophy',
        Category: 'Central nervous system disorders',
    },
    { value: 'Muscles', label: 'Muscles', Category: 'Musculo-skeletal disorders' },
    {
        value: 'Myasthenia gravis',
        label: 'Myasthenia gravis',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Myocardial infarction (heart attack)',
        label: 'Myocardial infarction (heart attack)',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Other autoimmune disorders',
        label: 'Other autoimmune disorders',
        Category: 'Auto-immune disorders',
    },
    {
        value: 'Other central nervous system disorders',
        label: 'Other central nervous system disorders',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Pancreatic disorders',
        label: 'Pancreatic disorders',
        Category: 'Gastrointestinal disorders',
    },
    { value: 'Paralysis', label: 'Paralysis', Category: 'Central nervous system disorders' },
    {
        value: `Parkinson's disease`,
        label: `Parkinson's disease`,
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Pericardial disease',
        label: 'Pericardial disease',
        Category: 'Cardiovascular disorders',
    },
    {
        value: 'Peripheral arterial disease',
        label: 'Peripheral arterial disease',
        Category: 'Cardiovascular disorders',
    },
    { value: 'PTSD', label: 'PTSD', Category: 'Mental disorders' },
    { value: 'Renal disorders', label: 'Renal disorders', Category: 'Renal disorders' },
    {
        value: 'Respiratory disorders',
        label: 'Respiratory disorders',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Rheumatoid arthritis',
        label: 'Rheumatoid arthritis',
        Category: 'Auto-immune disorders',
    },
    {
        value: 'Speech disorders',
        label: 'Speech disorders',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Sleep disorders',
        label: 'Sleep disorders',
        Category: 'Central nervous system disorders',
    },
    {
        value: 'Schizophrenia/other psychotic disorders',
        label: 'Schizophrenia/other psychotic disorders',
        Category: 'Mental disorders',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
        Category: 'Trauma',
    },
    { value: 'Upper air passages', label: 'Upper air passages', Category: 'Respiratory disorders' },
    {
        value: 'Urogenital disorders',
        label: 'Urogenital disorders',
        Category: 'Genitourinary disorders',
    },
    {
        value: 'Valvular and septal diseases of the heart',
        label: 'Valvular and septal diseases of the heart',
        Category: 'Cardiovascular disorders',
    },
    { value: 'Inability to work', label: 'Inability to work', Category: 'Other' },
    { value: 'Other', label: 'Other', Category: 'Other' },
];
