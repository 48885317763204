export const MajorClaimCause = [
    { value: 'Accidental', label: 'Accidental', category: 'Accidental' },
    {
        value: 'Activities of daily living',
        label: 'Activities of daily living',
        category: 'Activities of daily living',
    },
    {
        value: 'Auto-immune disorders',
        label: 'Auto-immune disorders',
        category: 'Auto-immune disorders',
    },
    { value: 'Blood disorders', label: 'Blood disorders', category: 'Blood disorders' },
    { value: 'Cancer', label: 'Cancer', category: 'Cancer' },
    {
        value: 'Cardiovascular disorders',
        label: 'Cardiovascular disorders',
        category: 'Cardiovascular disorders',
    },
    {
        value: 'Cerebrovascular disorders',
        label: 'Cerebrovascular disorders',
        category: 'Cerebrovascular disorders',
    },
    {
        value: 'Central nervous system disorders',
        label: 'Central nervous system disorders',
        category: 'Central nervous system disorders',
    },
    { value: 'HIV/AIDS', label: 'HIV/AIDS', category: 'HIV/AIDS' },
    {
        value: 'Ear, nose and throat disorders',
        label: 'Ear, nose and throat disorders',
        category: 'Ear, nose and throat disorders',
    },
    { value: 'Endocrine disorders', label: 'Endocrine disorders', category: 'Endocrine disorders' },
    {
        value: 'Gastrointestinal disorders',
        label: 'Gastrointestinal disorders',
        category: 'Gastrointestinal disorders',
    },
    {
        value: 'Genitourinary disorders',
        label: 'Genitourinary disorders',
        category: 'Genitourinary disorders',
    },
    { value: 'Major burns', label: 'Major burns', category: 'Major burns' },
    { value: 'Mental disorders', label: 'Mental disorders', category: 'Mental disorders' },
    {
        value: 'Musculo-skeletal disorders',
        label: 'Musculo-skeletal disorders',
        category: 'Musculo-skeletal disorders',
    },
    {
        value: 'Respiratory disorders',
        label: 'Respiratory disorders',
        category: 'Respiratory disorders',
    },
    { value: 'Skin disorders', label: 'Skin disorders', category: 'Skin disorders' },
    { value: 'The visual system', label: 'The visual system', category: 'The visual system' },
    { value: 'Trauma', label: 'Trauma', category: 'Trauma' },
    { value: 'Renal disorders', label: 'Renal disorders', category: 'Renal disorders' },
];
