export const ClaimConditionsDread = [
    {
        value:
            'Accidental HIV - Accidental HIV - Proof of accidental contraction HIV as per policy document',
        label:
            'Accidental HIV - Accidental HIV - Proof of accidental contraction HIV as per policy document',
        Category: 'Accidental HIV',
    },
    {
        value: 'AIDS - Proof of clinical manifestation of AIDS as per policy document',
        label: 'AIDS - Proof of clinical manifestation of AIDS as per policy document',
        Category: 'AIDS',
    },
    {
        value:
            "Alzheimer's disease and progressive dementia - On diagnosis of Alzheimer's disease or progressive dementia",
        label:
            "Alzheimer's disease and progressive dementia - On diagnosis of Alzheimer's disease or progressive dementia",
        Category: "Alzheimer's disease and progressive dementia",
    },
    {
        value:
            'Aneurysm - Endovascular surgery or stent to repair any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal, iliac and ileofemoral arteries',
        label:
            'Aneurysm - Endovascular surgery or stent to repair any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal, iliac and ileofemoral arteries',
        Category: 'Aneurysm',
    },
    {
        value: 'Aneurysm - Endovascular surgery or stent to repair any aortic aneurysm',
        label: 'Aneurysm - Endovascular surgery or stent to repair any aortic aneurysm',
        Category: 'Aneurysm',
    },
    {
        value:
            'Aneurysm - Surgery (including by-pass graft or keyhole surgery) to repair an aneurysm, dissection or a narrowing of the thoracic or abdominal aorta',
        label:
            'Aneurysm - Surgery (including by-pass graft or keyhole surgery) to repair an aneurysm, dissection or a narrowing of the thoracic or abdominal aorta',
        Category: 'Aneurysm',
    },
    {
        value:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of an ileo-femoral aneurysm',
        label:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of an ileo-femoral aneurysm',
        Category: 'Aneurysm',
    },
    {
        value:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal and iliac arteries',
        label:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal and iliac arteries',
        Category: 'Aneurysm',
    },
    {
        value: 'Aplastic anaemia and other blood disorders - A bone marrow transplantation',
        label: 'Aplastic anaemia and other blood disorders - A bone marrow transplantation',
        Category: 'Aplastic anaemia and other blood disorders',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Aplastic anaemia and other blood disorders requiring repeated blood or blood product transfusion',
        label:
            'Aplastic anaemia and other blood disorders - Aplastic anaemia and other blood disorders requiring repeated blood or blood product transfusion',
        Category: 'Aplastic anaemia and other blood disorders',
    },
    {
        value: 'Aplastic anaemia and other blood disorders - Aplastic anaemia/bone marrow aplasia',
        label: 'Aplastic anaemia and other blood disorders - Aplastic anaemia/bone marrow aplasia',
        Category: 'Aplastic anaemia and other blood disorders',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome coring 2.5 or higher on International Prognostic Scoring System (IPPS)',
        label:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome coring 2.5 or higher on International Prognostic Scoring System (IPPS)',
        Category: 'Aplastic anaemia and other blood disorders',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome requiring bone marrow transplant',
        label:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome requiring bone marrow transplant',
        Category: 'Aplastic anaemia and other blood disorders',
    },
    {
        value: 'Aplastic anaemia and other blood disorders - Myeloproliferitive disorder',
        label: 'Aplastic anaemia and other blood disorders - Myeloproliferitive disorder',
        Category: 'Aplastic anaemia and other blood disorders',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Polycythemia vera with major organ failure',
        label:
            'Aplastic anaemia and other blood disorders - Polycythemia vera with major organ failure',
        Category: 'Aplastic anaemia and other blood disorders',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone a defibrillator insertion',
        label: 'Arrhythmia - Arrhythmia having undergone a defibrillator insertion',
        Category: 'Arrhythmia',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone a permanent pacemaker insertion',
        label: 'Arrhythmia - Arrhythmia having undergone a permanent pacemaker insertion',
        Category: 'Arrhythmia',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone pathway ablation',
        label: 'Arrhythmia - Arrhythmia having undergone pathway ablation',
        Category: 'Arrhythmia',
    },
    {
        value: 'Arrhythmia - Intravenous inotropic support for more than 2 days',
        label: 'Arrhythmia - Intravenous inotropic support for more than 2 days',
        Category: 'Arrhythmia',
    },
    {
        value: 'Arrhythmia - Permanent atrial fibrillation on optimal treatment',
        label: 'Arrhythmia - Permanent atrial fibrillation on optimal treatment',
        Category: 'Arrhythmia',
    },
    {
        value: 'BCC/SCC',
        label: 'BCC/SCC',
        Category: 'Cancer',
    },
    {
        value: 'Cancer and leukaemia - Bladder cancer',
        label: 'Cancer and leukaemia - Bladder cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Blood/bone marrow cancer',
        label: 'Cancer and leukaemia - Blood/bone marrow cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Brain cancer',
        label: 'Cancer and leukaemia - Brain cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Breast cancer',
        label: 'Cancer and leukaemia - Breast cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Cervical cancer',
        label: 'Cancer and leukaemia - Cervical cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Colorectal cancer',
        label: 'Cancer and leukaemia - Colorectal cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Endometrial cancer',
        label: 'Cancer and leukaemia - Endometrial cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Eye cancer',
        label: 'Cancer and leukaemia - Eye cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Liver cancer',
        label: 'Cancer and leukaemia - Liver cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Lung cancer',
        label: 'Cancer and leukaemia - Lung cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Lymph cancer',
        label: 'Cancer and leukaemia - Lymph cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Metastatic cancer',
        label: 'Cancer and leukaemia - Metastatic cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Oesophageal cancer',
        label: 'Cancer and leukaemia - Oesophageal cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Other cancer',
        label: 'Cancer and leukaemia - Other cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Ovarian cancer',
        label: 'Cancer and leukaemia - Ovarian cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Pancreatic cancer',
        label: 'Cancer and leukaemia - Pancreatic cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Prostate cancer',
        label: 'Cancer and leukaemia - Prostate cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Renal cancer',
        label: 'Cancer and leukaemia - Renal cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Skin cancer',
        label: 'Cancer and leukaemia - Skin cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Stomach cancer',
        label: 'Cancer and leukaemia - Stomach cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Testicular cancer',
        label: 'Cancer and leukaemia - Testicular cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Tongue cancer',
        label: 'Cancer and leukaemia - Tongue cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Thyroid cancer',
        label: 'Cancer and leukaemia - Thyroid cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Uterus cancer',
        label: 'Cancer and leukaemia - Uterus cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Vulva cancer',
        label: 'Cancer and leukaemia - Vulva cancer',
        Category: 'Cancer and leukaemia',
    },
    {
        value: 'Cardiomyopathy - Heart transplant',
        label: 'Cardiomyopathy - Heart transplant',
        Category: 'Cardiomyopathy',
    },
    {
        value:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical) for a cardiomyopathy',
        label:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical) for a cardiomyopathy',
        Category: 'Cardiomyopathy',
    },
    {
        value:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical),for a cardiomyopathy',
        label:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical),for a cardiomyopathy',
        Category: 'Cardiomyopathy',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as confirmed irreversible Cor Pulmonale',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as confirmed irreversible Cor Pulmonale',
        Category: 'Cardio-pulmonary dysfunction',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as primary pulmonary hypertension',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as primary pulmonary hypertension',
        Category: 'Cardio-pulmonary dysfunction',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as pulmonary venous occlusive disease',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as pulmonary venous occlusive disease',
        Category: 'Cardio-pulmonary dysfunction',
    },
    {
        value:
            'Carotid arterial disease - Angioplasty with or without stenting in both carotid arteries',
        label:
            'Carotid arterial disease - Angioplasty with or without stenting in both carotid arteries',
        Category: 'Carotid arterial disease',
    },
    {
        value:
            'Carotid arterial disease - Angioplasty with or without stenting in one carotid artery',
        label:
            'Carotid arterial disease - Angioplasty with or without stenting in one carotid artery',
        Category: 'Carotid arterial disease',
    },
    {
        value:
            'Carotid arterial disease - By-pass graft or endarterectomy in both carotid arteries',
        label:
            'Carotid arterial disease - By-pass graft or endarterectomy in both carotid arteries',
        Category: 'Carotid arterial disease',
    },
    {
        value: 'Carotid arterial disease - By-pass graft or endarterectomy in one carotid artery',
        label: 'Carotid arterial disease - By-pass graft or endarterectomy in one carotid artery',
        Category: 'Carotid arterial disease',
    },
    {
        value: 'Carcinoid',
        label: 'Carcinoid',
        Category: 'Carcinoid',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - A stroke where there are residual symptoms or signs and a full recovery is not made',
        label:
            'Cerebrovascular incident (Stoke) - A stroke where there are residual symptoms or signs and a full recovery is not made',
        Category: 'Cerebrovascular incident (Stoke)',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - On diagnosis of a stroke as defined in the policy document',
        label:
            'Cerebrovascular incident (Stoke) - On diagnosis of a stroke as defined in the policy document',
        Category: 'Cerebrovascular incident (Stoke)',
    },
    {
        value: 'Cerebrovascular incident (Stoke) - Stroke resulting in full recovery',
        label: 'Cerebrovascular incident (Stoke) - Stroke resulting in full recovery',
        Category: 'Cerebrovascular incident (Stoke)',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment ',
        label:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment ',
        Category: 'Cerebrovascular incident (Stoke)',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment measured by',
        label:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment measured by',
        Category: 'Cerebrovascular incident (Stoke)',
    },
    {
        value: 'Cerebrovascular incident (Stoke) - Subarachnoid haemorrhage',
        label: 'Cerebrovascular incident (Stoke) - Subarachnoid haemorrhage',
        Category: 'Cerebrovascular incident (Stoke)',
    },
    {
        value: 'Chronic liver disorders - A liver transplant or total hepatectomy',
        label: 'Chronic liver disorders - A liver transplant or total hepatectomy',
        Category: 'Chronic liver disorders',
    },
    {
        value: 'Chronic liver disorders - End-stage liver failure',
        label: 'Chronic liver disorders - End-stage liver failure',
        Category: 'Chronic liver disorders',
    },
    {
        value: 'Chronic liver disorders - Fulminant hepatic necrosis',
        label: 'Chronic liver disorders - Fulminant hepatic necrosis',
        Category: 'Chronic liver disorders',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class A',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class A',
        Category: 'Chronic liver disorders',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class B',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class B',
        Category: 'Chronic liver disorders',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class C',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class C',
        Category: 'Chronic liver disorders',
    },
    {
        value:
            'Chronic liver disorders - On confirmed diagnosis of primary amyloidosis of the liver or spleen',
        label:
            'Chronic liver disorders - On confirmed diagnosis of primary amyloidosis of the liver or spleen',
        Category: 'Chronic liver disorders',
    },
    {
        value: 'Chronic liver disorders - Partial hepatectomy due to disease',
        label: 'Chronic liver disorders - Partial hepatectomy due to disease',
        Category: 'Chronic liver disorders',
    },
    {
        value: 'Chronic liver disorders - Portal hypertesion',
        label: 'Chronic liver disorders - Portal hypertesion',
        Category: 'Chronic liver disorders',
    },
    {
        value:
            'Coma - Coma meeting the description in the policy document and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Coma - Coma meeting the description in the policy document and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
        Category: 'Coma',
    },
    {
        value:
            'Coma - Coma meeting the description in the policy document and the comatose state is persistent for longer than 96 hours',
        label:
            'Coma - Coma meeting the description in the policy document and the comatose state is persistent for longer than 96 hours',
        Category: 'Coma',
    },
    {
        value: 'Coronary artery disease - Angioplasty with or without stenting',
        label: 'Coronary artery disease - Angioplasty with or without stenting',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Coronary artery disease - Heart transplant',
        label: 'Coronary artery disease - Heart transplant',
        Category: 'Coronary artery disease',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, any one coronary artery by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, any one coronary artery by means of a by-pass graft',
        Category: 'Coronary artery disease',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, three or more coronary arteries by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, three or more coronary arteries by means of a by-pass graft',
        Category: 'Coronary artery disease',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, two coronary arteries by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, two coronary arteries by means of a by-pass graft',
        Category: 'Coronary artery disease',
    },
    {
        value:
            'Coronary artery disease - Unstable angina requiring intravenous thrombolytic therapy (excluding anticoagulant and anti-platelet agents)',
        label:
            'Coronary artery disease - Unstable angina requiring intravenous thrombolytic therapy (excluding anticoagulant and anti-platelet agents)',
        Category: 'Coronary artery disease',
    },
    {
        value: 'Ear, nose and throat disorders - Cochlear or middle ear implant',
        label: 'Ear, nose and throat disorders - Cochlear or middle ear implant',
        Category: 'Ear, nose and throat disorders',
    },
    {
        value: 'Ear, nose and throat disorders - Hearing loss',
        label: 'Ear, nose and throat disorders - Hearing loss',
        Category: 'Ear, nose and throat disorders',
    },
    {
        value: 'Ear, nose and throat disorders - Mastoiditis',
        label: 'Ear, nose and throat disorders - Mastoiditis',
        Category: 'Ear, nose and throat disorders',
    },
    {
        value: 'Ear, nose and throat disorders - Nasal bone destruction',
        label: 'Ear, nose and throat disorders - Nasal bone destruction',
        Category: 'Ear, nose and throat disorders',
    },
    {
        value: 'Ear, nose and throat disorders - Otosclerosis',
        label: 'Ear, nose and throat disorders - Otosclerosis',
        Category: 'Ear, nose and throat disorders',
    },
    {
        value: 'Ear, nose and throat disorders - Tympanosclerosis',
        label: 'Ear, nose and throat disorders - Tympanosclerosis',
        Category: 'Ear, nose and throat disorders',
    },
    {
        value: 'Endocrine conditions - Acromegaly',
        label: 'Endocrine conditions - Acromegaly',
        Category: 'Endocrine conditions',
    },
    {
        value: 'Endocrine conditions - Addisonian crisis',
        label: 'Endocrine conditions - Addisonian crisis',
        Category: 'Endocrine conditions',
    },
    {
        value: "Endocrine conditions - Conn's syndrome",
        label: "Endocrine conditions - Conn's syndrome",
        Category: 'Endocrine conditions',
    },
    {
        value: "Endocrine conditions - Cushing's syndrome",
        label: "Endocrine conditions - Cushing's syndrome",
        Category: 'Endocrine conditions',
    },
    {
        value: 'Endocrine conditions - Diabetes insipidus',
        label: 'Endocrine conditions - Diabetes insipidus',
        Category: 'Endocrine conditions',
    },
    {
        value: 'Endocrine conditions - Endocrine tumours having undergone surgical excision',
        label: 'Endocrine conditions - Endocrine tumours having undergone surgical excision',
        Category: 'Endocrine conditions',
    },
    {
        value: 'Endocrine conditions - Insulin treatment for Type II diabetes mellitus',
        label: 'Endocrine conditions - Insulin treatment for Type II diabetes mellitus',
        Category: 'Endocrine conditions',
    },
    {
        value: 'Endocrine conditions - Parathyroid tetany',
        label: 'Endocrine conditions - Parathyroid tetany',
        Category: 'Endocrine conditions',
    },
    {
        value: "Endocrine conditions - Sheehan's syndrome",
        label: "Endocrine conditions - Sheehan's syndrome",
        Category: 'Endocrine conditions',
    },
    {
        value: "Endocrine conditions - Simmond's disease",
        label: "Endocrine conditions - Simmond's disease",
        Category: 'Endocrine conditions',
    },
    {
        value: 'Endocrine conditions - Thyrotoxic crisis',
        label: 'Endocrine conditions - Thyrotoxic crisis',
        Category: 'Endocrine conditions',
    },
    {
        value: 'Endocrine conditions - Type I diabetes',
        label: 'Endocrine conditions - Type I diabetes',
        Category: 'Endocrine conditions',
    },
    {
        value: 'Eye disorders - Chronic corneal ulcer',
        label: 'Eye disorders - Chronic corneal ulcer',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Corneal transplant',
        label: 'Eye disorders - Corneal transplant',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Irreversible diabetic retinopathy (Grade III)',
        label: 'Eye disorders - Irreversible diabetic retinopathy (Grade III)',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Irreversible diabetic retinopathy (Grade IV)',
        label: 'Eye disorders - Irreversible diabetic retinopathy (Grade IV)',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Irreversible hemianopia in both eyes',
        label: 'Eye disorders - Irreversible hemianopia in both eyes',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Irreversible hemianopia in one eye',
        label: 'Eye disorders - Irreversible hemianopia in one eye',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Irreversible loss of sight in both eyes',
        label: 'Eye disorders - Irreversible loss of sight in both eyes',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Irreversible loss of sight in one eye',
        label: 'Eye disorders - Irreversible loss of sight in one eye',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Optic neuritis',
        label: 'Eye disorders - Optic neuritis',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Orbital abscess',
        label: 'Eye disorders - Orbital abscess',
        Category: 'Eye disorders',
    },
    {
        value: 'Eye disorders - Retinal detachment',
        label: 'Eye disorders - Retinal detachment',
        Category: 'Eye disorders',
    },
    {
        value: "Gastrointestinal disease - Crohn's disease or Ulcerative Colitis",
        label: "Gastrointestinal disease - Crohn's disease or Ulcerative Colitis",
        Category: 'Gastrointestinal disease',
    },
    {
        value: 'Gastrointestinal disease - Rectal fistula having undergone surgery',
        label: 'Gastrointestinal disease - Rectal fistula having undergone surgery',
        Category: 'Gastrointestinal disease',
    },
    {
        value: 'Gastrointestinal disease - Total colectomy',
        label: 'Gastrointestinal disease - Total colectomy',
        Category: 'Gastrointestinal disease',
    },
    {
        value: 'Gastrointestinal disease - Trachael oesophageal fistula having undergone surgery',
        label: 'Gastrointestinal disease - Trachael oesophageal fistula having undergone surgery',
        Category: 'Gastrointestinal disease',
    },
    {
        value: 'Heart transplant - Heart transplant',
        label: 'Heart transplant - Heart transplant',
        Category: 'Heart transplant',
    },
    {
        value: 'Living Lifestyle catch-all - Diagnosis of a terminal illness',
        label: 'Living Lifestyle catch-all - Diagnosis of a terminal illness',
        Category: 'Living Lifestyle catch-all',
    },
    {
        value:
            'Living Lifestyle catch-all - Permanent inability to independently perform Activities of Daily Living',
        label:
            'Living Lifestyle catch-all - Permanent inability to independently perform Activities of Daily Living',
        Category: 'Living Lifestyle catch-all',
    },
    {
        value: 'Living Lifestyle catch-all - Whole Person Impairment',
        label: 'Living Lifestyle catch-all - Whole Person Impairment',
        Category: 'Living Lifestyle catch-all',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 foot',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 foot',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand and 1 foot',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand and 1 foot',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 limb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 limb',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 feet',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 feet',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 hands',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 hands',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value:
            'Loss of and loss of use of limbs - Loss of use of or loss of 3 or more fingers on the same hand',
        label:
            'Loss of and loss of use of limbs - Loss of use of or loss of 3 or more fingers on the same hand',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of a thumb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of a thumb',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of more than one limb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of more than one limb',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Loss of and loss of use of limbs - Major joint replacement',
        label: 'Loss of and loss of use of limbs - Major joint replacement',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value:
            'Loss of and loss of use of limbs - Surgical repair of major motor nerve after complete severance',
        label:
            'Loss of and loss of use of limbs - Surgical repair of major motor nerve after complete severance',
        Category: 'Loss of and loss of use of limbs',
    },
    {
        value: 'Major burns - Full thickness burns',
        label: 'Major burns - Full thickness burns',
        Category: 'Major burns',
    },
    {
        value: 'Major burns - Partial thickness burns',
        label: 'Major burns - Partial thickness burns',
        Category: 'Major burns',
    },
    {
        value: 'Major burns - One hand',
        label: 'Major burns - One hand',
        Category: 'Major burns',
    },
    {
        value: 'Major burns - Other: Major Burns (%)',
        label: 'Major burns - Other: Major Burns (%)',
        Category: 'Major burns',
    },
    {
        value: 'Major burns - The face, the airways or both hands',
        label: 'Major burns - The face, the airways or both hands',
        Category: 'Major burns',
    },
    {
        value:
            'Motor neuron disease - On diagnosis of motor neurone disease by an appropriate specialist',
        label:
            'Motor neuron disease - On diagnosis of motor neurone disease by an appropriate specialist',
        Category: 'Motor neuron disease',
    },
    {
        value: 'Multiple sclerosis - On diagnosis of multiple sclerosis',
        label: 'Multiple sclerosis - On diagnosis of multiple sclerosis',
        Category: 'Multiple sclerosis',
    },
    {
        value: 'Multiple sclerosis - On diagnosis of progressive multiple sclerosis',
        label: 'Multiple sclerosis - On diagnosis of progressive multiple sclerosis',
        Category: 'Multiple sclerosis',
    },
    {
        value: 'Malignant melanoma',
        label: 'Malignant melanoma',
        Category: 'Malignant melanoma',
    },
    {
        value: 'Multiple myeloma',
        label: 'Multiple myeloma',
        Category: 'Multiple myeloma',
    },
    {
        value:
            'Multiple sclerosis - Optic neuritis where 2 or more plaques are seen to be demyelinating on an MRI',
        label:
            'Multiple sclerosis - Optic neuritis where 2 or more plaques are seen to be demyelinating on an MRI',
        Category: 'Multiple sclerosis',
    },
    {
        value:
            'Multiple sclerosis - Relapsing-remitting multiple sclerosis resulting in the permanent inability to independently perform Activities of Daily Living',
        label:
            'Multiple sclerosis - Relapsing-remitting multiple sclerosis resulting in the permanent inability to independently perform Activities of Daily Living',
        Category: 'Multiple sclerosis',
    },
    {
        value:
            'Muscular dystrophy - Muscular dystrophy resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Muscular dystrophy - Muscular dystrophy resulting in permanent inability to independently perform Activities of Daily Living',
        Category: 'Muscular dystrophy',
    },
    {
        value: 'Muscular dystrophy - On diagnosis of muscular dystrophy',
        label: 'Muscular dystrophy - On diagnosis of muscular dystrophy',
        Category: 'Muscular dystrophy',
    },
    {
        value:
            'Myasthenia gravis - Myasthenia gravis resulting in the permanent inability to independently perform activities of daily living',
        label:
            'Myasthenia gravis - Myasthenia gravis resulting in the permanent inability to independently perform activities of daily living',
        Category: 'Myasthenia gravis',
    },
    {
        value: 'Myasthenia gravis - On diagnosis of myasthenia gravis',
        label: 'Myasthenia gravis - On diagnosis of myasthenia gravis',
        Category: 'Myasthenia gravis',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Acute heart failure as diagnosed by an appropriate specialist and EF < 45 % at time of diagnosis',
        label:
            'Myocardial infarction (heart attack) - Acute heart failure as diagnosed by an appropriate specialist and EF < 45 % at time of diagnosis',
        Category: 'Myocardial infarction (heart attack)',
    },
    {
        value: 'Myocardial infarction (heart attack) - Heart transplant',
        label: 'Myocardial infarction (heart attack) - Heart transplant',
        Category: 'Myocardial infarction (heart attack)',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Myocardial infarction as defined in the policy document, but Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml',
        label:
            'Myocardial infarction (heart attack) - Myocardial infarction as defined in the policy document, but Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml',
        Category: 'Myocardial infarction (heart attack)',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Myocardial infarction resulting in at least 2 of the following: ',
        label:
            'Myocardial infarction (heart attack) - Myocardial infarction resulting in at least 2 of the following: ',
        Category: 'Myocardial infarction (heart attack)',
    },
    {
        value:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction',
        label:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction',
        Category: 'Myocardial infarction (heart attack)',
    },
    {
        value:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction; and receiving optimal treatment (whether that is medical or surgical)',
        label:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction; and receiving optimal treatment (whether that is medical or surgical)',
        Category: 'Myocardial infarction (heart attack)',
    },
    {
        value: 'Other autoimmune disorders - Arteritis or endarteritis',
        label: 'Other autoimmune disorders - Arteritis or endarteritis',
        Category: 'Other autoimmune disorders',
    },
    {
        value: 'Other autoimmune disorders - Complications involving the musculoskeletal system',
        label: 'Other autoimmune disorders - Complications involving the musculoskeletal system',
        Category: 'Other autoimmune disorders',
    },
    {
        value: 'Other autoimmune disorders - Disorders involving one major organ',
        label: 'Other autoimmune disorders - Disorders involving one major organ',
        Category: 'Other autoimmune disorders',
    },
    {
        value:
            'Other autoimmune disorders - Disorders producing systoms and involving one or more major organs',
        label:
            'Other autoimmune disorders - Disorders producing systoms and involving one or more major organs',
        Category: 'Other autoimmune disorders',
    },
    {
        value:
            'Other autoimmune disorders - Disorders producing systoms and involving three or more major organs',
        label:
            'Other autoimmune disorders - Disorders producing systoms and involving three or more major organs',
        Category: 'Other autoimmune disorders',
    },
    {
        value:
            'Other autoimmune disorders - Disorders producing systoms and involving two or more major organs',
        label:
            'Other autoimmune disorders - Disorders producing systoms and involving two or more major organs',
        Category: 'Other autoimmune disorders',
    },
    {
        value:
            'Other autoimmune disorders - Disorders resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Other autoimmune disorders - Disorders resulting in permanent inability to independently perform Activities of Daily Living',
        Category: 'Other autoimmune disorders',
    },
    {
        value:
            'Other autoimmune disorders - Disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
        label:
            'Other autoimmune disorders - Disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
        Category: 'Other autoimmune disorders',
    },
    {
        value:
            'Other central nervous system disorders - Any condition or brain abscess having undergone open brain surgery',
        label:
            'Other central nervous system disorders - Any condition or brain abscess having undergone open brain surgery',
        Category: 'Other central nervous system disorders',
    },
    {
        value: 'Other central nervous system disorders - Benign brain tumour',
        label: 'Other central nervous system disorders - Benign brain tumour',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Benign brain tumour or brain abscess, intracranial aneurysm, or arterio-venous malformation resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        label:
            'Other central nervous system disorders - Benign brain tumour or brain abscess, intracranial aneurysm, or arterio-venous malformation resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Brain abscess having undergone surgical drainage',
        label:
            'Other central nervous system disorders - Brain abscess having undergone surgical drainage',
        Category: 'Other central nervous system disorders',
    },
    {
        value: 'Other central nervous system disorders - Cerebral malaria',
        label: 'Other central nervous system disorders - Cerebral malaria',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Cerebral malaria resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        label:
            'Other central nervous system disorders - Cerebral malaria resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Cerebral oedema having undergone in-hospital treatment with specific therapeutic interventions',
        label:
            'Other central nervous system disorders - Cerebral oedema having undergone in-hospital treatment with specific therapeutic interventions',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Conditions having undergone open brain surgery excluding arterio-venous malformation or intracranial aneurysm',
        label:
            'Other central nervous system disorders - Conditions having undergone open brain surgery excluding arterio-venous malformation or intracranial aneurysm',
        Category: 'Other central nervous system disorders',
    },
    {
        value: 'Other central nervous system disorders - Guillain-BarrÃ© syndrome',
        label: 'Other central nervous system disorders - Guillain-BarrÃ© syndrome',
        Category: 'Other central nervous system disorders',
    },
    {
        value: 'Other central nervous system disorders - Hydrocephalus',
        label: 'Other central nervous system disorders - Hydrocephalus',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Intracranial aneurysm or arterio-venous malformation',
        label:
            'Other central nervous system disorders - Intracranial aneurysm or arterio-venous malformation',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Permanent and complete inability to communicate or comprehend language symbols',
        label:
            'Other central nervous system disorders - Permanent and complete inability to communicate or comprehend language symbols',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Raised intracranial pressure causing symptoms',
        label:
            'Other central nervous system disorders - Raised intracranial pressure causing symptoms',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Recurrent benign brain tumour showing symptoms',
        label:
            'Other central nervous system disorders - Recurrent benign brain tumour showing symptoms',
        Category: 'Other central nervous system disorders',
    },
    {
        value:
            'Other central nervous system disorders - Status epilepticus (unremitting convulsions) resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        label:
            'Other central nervous system disorders - Status epilepticus (unremitting convulsions) resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        Category: 'Other central nervous system disorders',
    },
    {
        value: 'Pancreatic disorders - A pancreas transplant',
        label: 'Pancreatic disorders - A pancreas transplant',
        Category: 'Pancreatic disorders',
    },
    {
        value: 'Pancreatic disorders - Chronic pancreatitis',
        label: 'Pancreatic disorders - Chronic pancreatitis',
        Category: 'Pancreatic disorders',
    },
    {
        value: 'Pancreatic disorders - Complete pancreatectomy',
        label: 'Pancreatic disorders - Complete pancreatectomy',
        Category: 'Pancreatic disorders',
    },
    {
        value: 'Pancreatic disorders - Pancreatic abscess having undergone surgical drainage',
        label: 'Pancreatic disorders - Pancreatic abscess having undergone surgical drainage',
        Category: 'Pancreatic disorders',
    },
    {
        value: 'Pancreatic disorders - Partial pancreatectomy due to disease',
        label: 'Pancreatic disorders - Partial pancreatectomy due to disease',
        Category: 'Pancreatic disorders',
    },
    {
        value: 'Paralysis - Diplegia',
        label: 'Paralysis - Diplegia',
        Category: 'Paralysis',
    },
    {
        value: 'Paralysis - Hemiplegia',
        label: 'Paralysis - Hemiplegia',
        Category: 'Paralysis',
    },
    {
        value: 'Paralysis - Paraplegia',
        label: 'Paralysis - Paraplegia',
        Category: 'Paralysis',
    },
    {
        value: 'Paralysis - Quadriplegia',
        label: 'Paralysis - Quadriplegia',
        Category: 'Paralysis',
    },
    {
        value: "Parkinson's disease - On diagnosis of Parkinson's disease",
        label: "Parkinson's disease - On diagnosis of Parkinson's disease",
        Category: "Parkinson's disease",
    },
    {
        value:
            "Parkinson's disease - Parkinson's disease resulting in the permanent inability to independently perform Activities of Daily Living",
        label:
            "Parkinson's disease - Parkinson's disease resulting in the permanent inability to independently perform Activities of Daily Living",
        Category: "Parkinson's disease",
    },
    {
        value:
            'Pericardial disease - Acute or chronic pericarditis as seen on special investigation',
        label:
            'Pericardial disease - Acute or chronic pericarditis as seen on special investigation',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - Keyhole pericardiectomy',
        label: 'Pericardial disease - Keyhole pericardiectomy',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - Open-heart pericardiectomy',
        label: 'Pericardial disease - Open-heart pericardiectomy',
        Category: 'Pericardial disease',
    },
    {
        value: 'Pericardial disease - Pericardiectomy done endoscopically',
        label: 'Pericardial disease - Pericardiectomy done endoscopically',
        Category: 'Pericardial disease',
    },
    {
        value:
            'Pericardial disease - Receiving optimal treatment (whether that is medical or surgical) for a pericardial disease',
        label:
            'Pericardial disease - Receiving optimal treatment (whether that is medical or surgical) for a pericardial disease',
        Category: 'Pericardial disease',
    },
    {
        value:
            'Peripheral arterial disease - Gangrene or loss of limb due to peripheral arterial disease',
        label:
            'Peripheral arterial disease - Gangrene or loss of limb due to peripheral arterial disease',
        Category: 'Peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - Occlusion of major artery (50% or more) as evidenced by positive imaging and clinical findings',
        label:
            'Peripheral arterial disease - Occlusion of major artery (50% or more) as evidenced by positive imaging and clinical findings',
        Category: 'Peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disease; and angioplasty with 2 or more stents in a single vessel or in separate vessels',
        label:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disease; and angioplasty with 2 or more stents in a single vessel or in separate vessels',
        Category: 'Peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disesse; and angioplasty with or without stenting of a peripheral artery',
        label:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disesse; and angioplasty with or without stenting of a peripheral artery',
        Category: 'Peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - Receiving optimal treatment (whether that is surgical or medical) for peripheral arterial disease, but still experiencing persistent claudication and leg ulcer/s, dystrophic changes or colour changes',
        label:
            'Peripheral arterial disease - Receiving optimal treatment (whether that is surgical or medical) for peripheral arterial disease, but still experiencing persistent claudication and leg ulcer/s, dystrophic changes or colour changes',
        Category: 'Peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - Surgery to correct the narrowing of, or blockage to, a peripheral artery / arteries by means of a by-pass graft',
        label:
            'Peripheral arterial disease - Surgery to correct the narrowing of, or blockage to, a peripheral artery / arteries by means of a by-pass graft',
        Category: 'Peripheral arterial disease',
    },
    {
        value: 'Renal disorders - Chronic nephrotic syndrome',
        label: 'Renal disorders - Chronic nephrotic syndrome',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Chronic tubulointerstitial disease',
        label: 'Renal disorders - Chronic tubulointerstitial disease',
        Category: 'Renal disorders',
    },
    {
        value:
            'Renal disorders - Chronic, irreversible kidney failure requiring and already having undergone regular dialysis treatment',
        label:
            'Renal disorders - Chronic, irreversible kidney failure requiring and already having undergone regular dialysis treatment',
        Category: 'Renal disorders',
    },
    {
        value:
            'Renal disorders - Endoscopic or minimally invasive renal surgery (excluding biopsy or any surgery for renal stones)',
        label:
            'Renal disorders - Endoscopic or minimally invasive renal surgery (excluding biopsy or any surgery for renal stones)',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Kidney transplant',
        label: 'Renal disorders - Kidney transplant',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Nephrotic syndrome with renal artery or renal vein thrombosis',
        label: 'Renal disorders - Nephrotic syndrome with renal artery or renal vein thrombosis',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Open kidney surgery not for diagnostic purposes',
        label: 'Renal disorders - Open kidney surgery not for diagnostic purposes',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Partial or total nephrectomy',
        label: 'Renal disorders - Partial or total nephrectomy',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Primary amyloidosis of the kidney',
        label: 'Renal disorders - Primary amyloidosis of the kidney',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Progressive chronic kidney disease',
        label: 'Renal disorders - Progressive chronic kidney disease',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Renal abscess',
        label: 'Renal disorders - Renal abscess',
        Category: 'Renal disorders',
    },
    {
        value: 'Renal disorders - Renal cortical necrosis',
        label: 'Renal disorders - Renal cortical necrosis',
        Category: 'Renal disorders',
    },
    {
        value: 'Respiratory disorders - Bronchietasis',
        label: 'Respiratory disorders - Bronchietasis',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Bronchopleural fistula having undergone surgery',
        label: 'Respiratory disorders - Bronchopleural fistula having undergone surgery',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Chronic irreversible lung diseases',
        label: 'Respiratory disorders - Chronic irreversible lung diseases',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Decortication',
        label: 'Respiratory disorders - Decortication',
        Category: 'Respiratory disorders',
    },
    {
        value:
            'Respiratory disorders - Drainage of empyema or lung abscess or pleural effusion requiring drainage',
        label:
            'Respiratory disorders - Drainage of empyema or lung abscess or pleural effusion requiring drainage',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Fibrosing alveolitis',
        label: 'Respiratory disorders - Fibrosing alveolitis',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Heart-lung transplant',
        label: 'Respiratory disorders - Heart-lung transplant',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Insertion of veno-caval filter',
        label: 'Respiratory disorders - Insertion of veno-caval filter',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Interstitial lung diseases',
        label: 'Respiratory disorders - Interstitial lung diseases',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Lung transplant',
        label: 'Respiratory disorders - Lung transplant',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Other open lung surgery not for diagnostic purposes',
        label: 'Respiratory disorders - Other open lung surgery not for diagnostic purposes',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Pleurectomy',
        label: 'Respiratory disorders - Pleurectomy',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Pneumoconiosis',
        label: 'Respiratory disorders - Pneumoconiosis',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Pulmonary emboli as seen on special investigations',
        label: 'Respiratory disorders - Pulmonary emboli as seen on special investigations',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Recurrent pulmonary embolism',
        label: 'Respiratory disorders - Recurrent pulmonary embolism',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Removal of a lung',
        label: 'Respiratory disorders - Removal of a lung',
        Category: 'Respiratory disorders',
    },
    {
        value: 'Respiratory disorders - Removal of one lobe of the lungs',
        label: 'Respiratory disorders - Removal of one lobe of the lungs',
        Category: 'Respiratory disorders',
    },
    {
        value:
            'Respiratory disorders - Status asthmaticus having undergone intubation and ICU admission',
        label:
            'Respiratory disorders - Status asthmaticus having undergone intubation and ICU admission',
        Category: 'Respiratory disorders',
    },
    {
        value:
            'Rheumatoid arthritis - Musculoskeletal involvement having undergone surgery including joint replacement, joint reconstruction or joint fixation',
        label:
            'Rheumatoid arthritis - Musculoskeletal involvement having undergone surgery including joint replacement, joint reconstruction or joint fixation',
        Category: 'Rheumatoid arthritis',
    },
    {
        value:
            'Rheumatoid arthritis - Musculoskeletal involvement of the major joints with evidence of ongoing therapy',
        label:
            'Rheumatoid arthritis - Musculoskeletal involvement of the major joints with evidence of ongoing therapy',
        Category: 'Rheumatoid arthritis',
    },
    {
        value:
            'Rheumatoid arthritis - Progressive systemic involvement of the heart or lungs or vasculitis despite optimal therapy',
        label:
            'Rheumatoid arthritis - Progressive systemic involvement of the heart or lungs or vasculitis despite optimal therapy',
        Category: 'Rheumatoid arthritis',
    },
    {
        value:
            'Rheumatoid arthritis - Rheumatoid arthritis resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Rheumatoid arthritis - Rheumatoid arthritis resulting in permanent inability to independently perform Activities of Daily Living',
        Category: 'Rheumatoid arthritis',
    },
    {
        value: 'Speech disorders - Permanent aphasia',
        label: 'Speech disorders - Permanent aphasia',
        Category: 'Speech disorders',
    },
    {
        value: 'Speech disorders - Permanent dysarthria',
        label: 'Speech disorders - Permanent dysarthria',
        Category: 'Speech disorders',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Admittance to a recognised physical or mental rehabilitation institution',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Admittance to a recognised physical or mental rehabilitation institution',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Decompression laminectomy',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Decompression laminectomy',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Drainage via burrhole',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Drainage via burrhole',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Emergency tracheostomy or cricothyrotomy',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Emergency tracheostomy or cricothyrotomy',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Head injury',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Head injury',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - ICU admission with mechanical ventilation for at least 96 hours',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - ICU admission with mechanical ventilation for at least 96 hours',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Le Fort II and III facial injuries',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Le Fort II and III facial injuries',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Major surgical reconstruction',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Major surgical reconstruction',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Orbital fracture',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Orbital fracture',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal fusion',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal fusion',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal injury resulting in paraplegia, diplegia, hemiplegia, quadriplegia and cauda equina syndrome',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal injury resulting in paraplegia, diplegia, hemiplegia, quadriplegia and cauda equina syndrome',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and the comatose state is persistent for longer than 96 hours',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and the comatose state is persistent for longer than 96 hours',
        Category:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    {
        value: 'Urogenital disorders - Partial amputation of the penis',
        label: 'Urogenital disorders - Partial amputation of the penis',
        Category: 'Urogenital disorders',
    },
    {
        value: 'Urogenital disorders - Partial cystectomy (removal of at least 50% of the bladder)',
        label: 'Urogenital disorders - Partial cystectomy (removal of at least 50% of the bladder)',
        Category: 'Urogenital disorders',
    },
    {
        value:
            'Urogenital disorders - Radical cystectomy resulting in a need for an external bag or catheterisation',
        label:
            'Urogenital disorders - Radical cystectomy resulting in a need for an external bag or catheterisation',
        Category: 'Urogenital disorders',
    },
    {
        value: 'Urogenital disorders - Total amputation of the penis',
        label: 'Urogenital disorders - Total amputation of the penis',
        Category: 'Urogenital disorders',
    },
    {
        value: 'Urogenital disorders - Unilateral or bilateral orchidectomy',
        label: 'Urogenital disorders - Unilateral or bilateral orchidectomy',
        Category: 'Urogenital disorders',
    },
    {
        value: 'Urogenital disorders - Vesico-vaginal fistula having undergone surgery',
        label: 'Urogenital disorders - Vesico-vaginal fistula having undergone surgery',
        Category: 'Urogenital disorders',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Endocarditis (as indicated by appropriate tests)',
        label:
            'Valvular and septal diseases of the heart - Endocarditis (as indicated by appropriate tests)',
        Category: 'Valvular and septal diseases of the heart',
    },
    {
        value: 'Valvular and septal diseases of the heart - Heart transplant',
        label: 'Valvular and septal diseases of the heart - Heart transplant',
        Category: 'Valvular and septal diseases of the heart',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Keyhole valvular surgery via the thoracic wall',
        label:
            'Valvular and septal diseases of the heart - Keyhole valvular surgery via the thoracic wall',
        Category: 'Valvular and septal diseases of the heart',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Open heart surgery for valvular conditions',
        label:
            'Valvular and septal diseases of the heart - Open heart surgery for valvular conditions',
        Category: 'Valvular and septal diseases of the heart',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Receiving optimal treatment (whether that is medical or surgical) for a valvular or septal heart disease',
        label:
            'Valvular and septal diseases of the heart - Receiving optimal treatment (whether that is medical or surgical) for a valvular or septal heart disease',
        Category: 'Valvular and septal diseases of the heart',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Surgical valvular procedure done endoscopically (including catheter-based procedures)',
        label:
            'Valvular and septal diseases of the heart - Surgical valvular procedure done endoscopically (including catheter-based procedures)',
        Category: 'Valvular and septal diseases of the heart',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Symptomatic atrial or ventricular septal defect having undergone surgical closure',
        label:
            'Valvular and septal diseases of the heart - Symptomatic atrial or ventricular septal defect having undergone surgical closure',
        Category: 'Valvular and septal diseases of the heart',
    },
    {
        value: 'WPI',
        label: 'WPI',
        Category: 'WPI',
    },
    {
        value: 'Other',
        label: 'Other',
        Category: 'Other',
    },
];
